/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import './infosider.css';
import froala1 from './Froala1.png';
import froala2 from './Froala2.png';
import num1 from './NumA1.png';
import num2 from './NumA2.png';
import num3 from './NumA3.png';
import num4 from './NumA4.png';
import Eta1 from './Eta1.jpg';
import Eta2 from './Eta2.png';
import Eta3 from './Eta3.jpg';
import Eta4 from './Eta4.jpg';
import fyring2 from './fyring2.png';
import pollux from './pollux.png';
import FagKronGreen from 'images/Annet/FagKronGreen.png';
import Forbud from 'images/forbud.png';
import FagKronYellow from 'images/Annet/FagKronYellow.png';
import FagKronRed from 'images/Annet/FagKronRed.png';
import skrivvarsel from './skrivvarsel.png';
import dokumentasjon from './dokumentasjon.docx';
import Nokkeltall from './nokkeltall.png';
import Feieadkomst from './image-6.png';
import cookie from 'react-cookies';
import eks_forh from './eks_forh.png';
import eks_forh2 from './eks_forh2.png';
import ris1 from './ris1.png';
import ris2 from './ris2.png';
import ris3 from './ris3.png';
import ris4 from './ris4.png';
import ris5 from './ris5.png';
import infoRis1 from './infoRis1.png';
import infoRis2 from './infoRis2.png';
import infoRis3 from './infoRis3.png';
import infoRis4 from './infoRis4.png';
import infoRis5 from './infoRis5.png';

export default class InfoOppdatering extends React.Component {
	render() {
		//================================= LAST INFO COOKIE ==============================================================
		const lastInfo = 21;
		//=================================================================================================================

		cookie.save('lastInfo', lastInfo, { path: '/' });
		return (
			<div style={{ marginLeft: '20px' }}>
				{/*  <h2>Informasjon</h2> */}
				<div className='panel panel-primary'>
					<div
						className='panel-heading collapsed'
						data-toggle='collapse'
						data-target='#nyheterOld'>
						<i className='fa fa-fw fa-chevron-down' />
						<i className='fa fa-fw fa-chevron-up' />
						<span style={{ marginLeft: '10px', fontSize: '22px' }}>
							Nyheter og oppdateringer
						</span>
					</div>
					<br />
					<div className='panel-body'>
						<div
							className='collapse in'
							id='nyheterOld'
							style={{ marginLeft: '20px' }}>
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2024-5'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>28.05.2024 - Ny funksjonalitet for kunder med KOMTEK Risikovurdering</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse in'
								id='2024-5'>
								<br />
								<b>Risikovurdering</b> <br />
								Det har nå kommet endringer for brukere som har risikovurdering. <br />
								Endringen innebærer forbedringer i veiviseren som starter etter &quot;Send til eier&quot;. <br />
								Hvis din organisasjon har KOMTEK Risikovurdering, så vil brukeren nå kunne se analyseresultatet etter hvert enkelt besøk, 
								før rapporten publiseres.  
								<br />
								<br />
								<b>
									<a
										href='https://komtek.zendesk.com/hc/no/articles/25429158578193-Ny-veiviser-for-KOMTEK-Brannforebygging-Risikovurdering'
										target='_blank'>
										Les mer om dette på vårt kundesenter
									</a>
								</b>
								<br />
								<br />
							</div>
							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2024-4'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>22.05.2024 - Teknisk oppgradering av risikoanalysen</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2024-4'>
								<br />
								<b>Oppgradering</b> <br />
								Vi har gjort oppgraderinger av komponenter som brukes av
								risikoanalysen i KOMTEK Brannforebygging. I utgangspunktet skal
								ikke disse endringene påvirke hvordan du bruker systemet, men
								det kan være greit å vite at vi har endret systemet fra å starte
								analysen etter hvert enkelt tiltak, til at analysen nå først
								starter når du trykker &quot;Send til eier&quot;.
								<br />
								<br />
								<b>
									<a
										href='https://komtek.zendesk.com/hc/no/articles/25226871855505-Ny-versjon-av-KOMTEK-Brannforebygging-Risikovurdering-21-05-24'
										target='_blank'>
										Les mer..
									</a>
								</b>
								<br />
								<br />
							</div>
							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2024-3'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>15.02.2024 - Innbyggerdialog og SMS</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2024-3'>
								<br />
								<b>Innbyggerdialog og SMS</b> <br />
								Ny versjon av Innbyggerdialogen og nye flettefelt for SMS-Maler
								er tilgjengelig.
								<br />
								<br />
								<b>
									<a
										href='https://komtek.zendesk.com/hc/no/articles/22567268444561-Ny-versjon-av-Innbyggerdialog-KOMTEK-Brannforebygging'
										target='_blank'>
										Les for mer info her..
									</a>
								</b>
								<br />
								<br />
							</div>
							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2024-2'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>18.01.2024 - Gratis webinar om risikovurdering</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2024-2'>
								<br />
								<b>Gratis webinar om risikovurdering</b> <br />
								Jobber du med brannforebygging? Da er kanskje dette gratis
								webinaret noe for deg? <br />
								I dette webinaret vil vi vise deg hvorfor KOMTEK
								Brannforebygging vil gjøre arbeidsdagen din <br />
								bedre, enklere og kanskje til og med morsommere!
								<br />
								<b>
									<a
										href='https://webinar.norkart.no/komtek-brannforebygging/join?utm_campaign=Brannforebygging%20sommeren%20%2723&utm_medium=email&_hsmi=2&_hsenc=p2ANqtz-9WyBDKo6k1Vnz5LCxG66GY4VqUWI0SKDUMggwxJrClTsUEmVYoEUNXpBHoU7RXVrQLFuWWHBGHoS2vxB4Uc62oayTiAA&utm_content=2&utm_source=hs_email'
										target='_blank'>
										Her er link til påmelding:
									</a>
								</b>
								<br />
								<br />
								<b>
									{' '}
									PS: Passer det ikke med tiden? <br />
									Meld deg på like vel, så kan du se det når det passer deg.{' '}
								</b>
								<br />
								<br />
							</div>
							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2024-1'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>09.01.2024 - SMS-maler</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2024-1'>
								<br />
								<b>Utvidelse av maloppsett (SMS-Maler)</b> <br />
								Det har nå kommet nye innstillinger for SMS-Maler i KOMTEK
								Brannforebygging. Dette lar brannvesenet selv tilpasse hva som
								skal stå i SMS’er som sendes til eiere etter besøk av
								Brannforebyggeren.
								<br />
								<br />
								<span style={{ textDecoration: 'underline' }}>
									Dere trenger ikke å gjøre noe
								</span>{' '}
								for at ting skal fungere, men dere har nå mulighet til å
								tilpasse deres egne maler for gjennomføring og oppfølging slik
								dere selv ønsker.
								<br />
								<br />
								Det er også lagt til funksjonalitet for å sette opp maler basert
								på spesifikke tiltak og kombinasjoner av tiltak som
								gjennomføres, slik som for planlegging.
								<br />
								Med den muligheten kan informasjonen etter et tiltak er utført
								eller ikke utført spesifiseres basert på om det har vært planer
								om å utføre tilsyn eller feiing, eller andre tiltak.
								<br />
								<br />
								<span style={{ color: 'red' }}>NB:</span> De nye standardmalene
								for SMS, som systemet{' '}
								<span style={{ textDecoration: 'underline' }}>nå</span>{' '}
								benytter, er endret noe for å treffe bedre på budskapet.
								<br />
								<br />
								Vi håper endringene faller i god smak. <br />
								<a
									href='https://komtek.zendesk.com/hc/no/articles/21568748912145-Utvidelse-av-maloppsett-SMS-Maler-i-KOMTEK-Brannforebygging'
									target='_blank'>
									Les artikkel
								</a>
								&nbsp;på vårt kundesenter.
								<br />
								<br />
							</div>
							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2023-9'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>07.11.2023 - Nye nøkkeltall for risko</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2023-9'>
								<br />
								Det har kommet en forbedring på visning av nøkkeltall for
								risikovurdering. Vi grupperer nå antall objekter for røykløp og
								enhet innen lav, middels og høy risiko. Dette for å gi bedre
								oversikt over endring i risikobildet. <br />
								Sjekk nøkkeltall ved å gå til{' '}
								<b>Rapporter → Nøkkeltall → Summering Risikotall</b>
								<br />
							</div>
							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2023-8'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											13.10.2023 - Endring i gjennomføring for kunder som har
											KOMTEK Risikovurdering
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2023-8'>
								<br />
								Vi har gjort flere justeringer og forbedringer av KOMTEK
								Risikovurdering i år. Ett av forbedringene som skulle forbedre
								brukeropplevelsen, var at resultatet fra analysen blir mer
								lesbart slik at det skulle bli lettere å forstå hvorfor objektet
								får den hyppigheten den får etter at tiltaket registreres. Nå
								kommer en ny tilpasning for å gjøre «Vis risikoanalyselogg»
								lettere tilgjengelig i situasjonsbildet.
								<br />
								<br />
								<img src={infoRis1} alt='' height='300px' />
								<br />
								<br />
								Dermed vil dere som har KOMTEK Risikovurdering nå få denne
								knappen i situasjonsbildet, og vil dermed enkelt kunne sjekke
								status for analysen som startes automatisk etter som de
								planlagte tiltakene registreres som utført eller ikke utført fra
								gjennomføring.
								<br />
								<br />
								<img src={infoRis2} alt='' height='200px' />
								<br />
								<br />
								Hvis status er blank og ikke grønn, slik bildet ovenfor viser,
								kan man trykke oppdater for å gjøre ny sjekk. Hvis status viser
								et rødt kryss, må det startes ny risikoanalyse.
								<br />
								<br />
								<img src={infoRis3} alt='' height='200px' />
								<br />
								<br />
								Velger du bruksenheten i loggen, vil du kunne se hvilke faktorer
								for sannsynlighet og hvilke faktorer for konsekvens som har
								slått til.
								<br />
								<br />
								<img src={infoRis4} alt='' height='300px' />
								<br />
								<br />
								For røykløpet kan det være andre faktorer som har slått til, men
								setter da eget risikotall og egen hyppighet for neste feiing:
								<br />
								<br />
								<img src={infoRis5} alt='' height='300px' />
								<br />
								<br />
								<a
									href='https://komtek.zendesk.com/hc/no/articles/18405547080209-Gjennomf%C3%B8r-planlagt-tiltak-og-start-analyse-via-Risikovurdering-i-KOMTEK-Brannforebygging'
									target='_blank'>
									Se brukerveiledning for KOMTEK Brannforebygging –
									risikovurdering her.
								</a>
								<br />
								<br />
							</div>
							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2023-7'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											08.09.2023 - Ny versjon av KOMTEK Brannforebygging
											risikovurdering
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2023-7'>
								<br />
								Mandag 11.09. slippes ny versjon av KOMTEK Brannforebygging
								risikovurdering! Den nye versjonen består nok meste at tekniske
								forbedringer, men vil også kunne gi våre brukere noen synlige
								opplevelser. Her er noe av det du kan forvente på mandag:
								<br />
								<br />
								<b>Ny kartvisning WMS i 'søk på enheter' og 'Planlegging' </b>
								<br />
								<br />
								<img src={ris1} alt='' height='200px' />
								<br />
								<br />
								<b>Vis analyseresultat på bruksenhet</b>
								<br />
								<br />
								<img src={ris2} alt='' height='200px' />
								<br />
								<br />
								<b>
									Her vil du kunne se hvilke faktorer som ligger til grunn for
									vurderingen av risikotall og hyppighet:
								</b>
								<br />
								<br />
								<img src={ris3} alt='' height='200px' />
								<br />
								<br />
								<img src={ris4} alt='' height='200px' />
								<br />
								Ovenfor er eksempel etter registrert tilsyn med analyseresultat
								for enhet og røykløp.
								<br />
								<br />
								<b>Nye nøkkeltall for risko</b>
								<br />
								<br />
								<img src={ris5} alt='' height='200px' />
								<br />
								<br />
								Det vil komme webinar til høsten hvor risikovurderingen blir
								hovedtema. Så følg med eller ta kontakt for mer informasjon.
							</div>

							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2023-6'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>11.08.2023 - Nytt oppsett for SMS-Maler</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2023-6'>
								<br />
								Mandag 14.08 kommer det en ny knapp under maloppsett for
								SMS-maler som heter «Ny mal for varsel». Denne knappen åpner opp
								for at ditt brannvesen kan opprette flere SMS-maler, som KOMTEK
								Brannforebygging kan bruke for å skille på innholdet i SMS som
								eier mottar ved varsling om besøk.
								<br />
								Utvidelsen betyr at brukere med administratorrettigheter nå kan
								sette opp unike maler og kombinasjoner slik at brannvesenet skal
								få nyttet seg av denne forbedringen.
								<br />
								<a
									href='https://komtek.zendesk.com/hc/no/articles/17710457291281-Nytt-oppsett-for-SMS-maler-i-KOMTEK-Brannforebygging'
									target='_blank'>
									Les mer om hvordan du kan sette opp nye maler på vårt
									kundesenter, her.
								</a>
								<br />
								<br />
							</div>

							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2023-5'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											10.08.2023 - KOMTEK Brannforebygging SvarUt –
											effektiviserer og sparer tid
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2023-5'>
								<br />
								KOMTEK Brannforebygging SvarUt korter ned prosesser og sørger
								for at brannvesenet på en effektiv måte når ikke-digitale
								innbyggere.
								<br />
								Se artikkel på kundesenteret vårt for mer info: <br />
								<a
									href='https://komtek.zendesk.com/hc/no/articles/17674977819153-KOMTEK-Brannforebygging-Bruk-SvarUt-N%C3%A5-ut-til-alle'
									target='_blank'>
									Les mer...
								</a>
								<br />
								<br />
							</div>

							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2023-4'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											19.06.2023 - Opptak av Webinar KOMTEK Brannforebygging
											(15.06)
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2023-4'>
								<br />
								Her kan du se opptak av webinaret som ble avholdt 15.06. Vi er
								så glad for at nesten 170 deltakere var med på livesendingen,
								men hvis dette har gått noen hus forbi i en travel hverdag, så
								vil du kunne få med deg dette webinaret likevel.
								<br />
								<br />
								<a
									href='https://vimeo.com/836813163/5ab4b4157c'
									target='_blank'>
									Link til video.
								</a>
								<br />
								<br />
							</div>

							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2023-3'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											02.06.2023 - Webinar KOMTEK Brannforebygging 15.06
											(13:00-14:00)
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2023-3'>
								<br />
								Webinaret tar for seg KOMTEK Brannforebygging som et effektivt
								verktøy for brannforebyggende arbeid. Vi ser også på de siste
								oppdateringene og hva vi jobber med nå. Vi vil se nærmere på
								disse temaene:
								<ul>
									<li>Boligsikkerhet</li>
									<li>Særskilte brannobjekt (§13)</li>
									<li>Søknader og bekymringsmeldinger</li>
									<li>Planlegging av tiltak</li>
									<li>Gjennomføring av tiltak</li>
									<li>Oppfølging etter tiltak</li>
									<li>Dokumentkontroll vha sideperson</li>
									<li>Dialog med digitale og ikke-digitale innbyggere</li>
									<li>Risikovurdering</li>
									<li>Arkivering</li>
									<li>Gebyrføring</li>
								</ul>
								<br />
								Dette passer både for deg som er godt kjent med KOMTEK
								Brannforebygging, for nyansatte og for dem som ønsker å bli
								bedre kjent med mulighetene løsningen tilbyr.
								<br />
								Det vil bli mulighet til å stille spørsmål under webinaret.
								<br />
								<br />
								For påmelding trykke her!
								<br />
								<br />
								<a
									href='https://events.teams.microsoft.com/event/97b96688-6488-49c7-8f77-4861fe952a07@0591ba1f-7671-4e03-9b2f-b1800a28a4ff?utm_campaign=Brannforebygging
                  %20sommeren%20%2723&utm_medium=email&_hsmi=72362234&_hsenc=p2ANqtz-_Ehbgd38P3XzRnZgAyyG-1r90VTen1mFt3cEHFeq6W0MJdes2yD8uPUQCtV1-Jm-
                  Lw9ge2eLIab8ILH_rOFfeLl_HpcA&utm_content=72362234&utm_source=hs_email'
									target='_blank'>
									Påmelding Webinar
								</a>
								<br />
								<br />
							</div>

							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2023-2'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>13.04.2023 - Ny versjon</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2023-2'>
								<br />
								Det har kommet flere signaler fra brukere om at det tar lang tid
								når KOMTEK Brannforebygging skal sende og arkivere rapport etter
								besøk. Dette kommer det forbedringer på, og brukeren vil nå få
								en helt ny opplevelse ved publisering og arkivering av besøk! I
								tillegg er det gjort endringer i fargekoder for arbeidslister i
								planlegging og gjennomføring, slik at livsløpet til en
								arbeidsliste blir ivaretatt, og at det blir bedre rutiner for å
								avslutte og slette arbeidslisten slik at arbeidslister ikke
								slettes før alle rapporter er publiserte og arkiverte.
								<br />
								<br />
								Se artikkel for mer detaljert beskrivelse:{' '}
								<a
									href='https://norkart.zendesk.com/knowledge/articles/14524529367313/no?brand_id=360005067777'
									target='_blank'>
									Les mer...
								</a>
								<br />
							</div>

							<br />

							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2023-1'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>08.03.2023 - Ny versjon av ‘Oppfølging’</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2023-1'>
								<br />
								I kveld (8/3) vil vi slippe ny versjon av fagsystemet. Da vil du
								oppleve en større endring i oppfølgingsprosessen i KOMTEK
								Brannforebygging. Du som Brannforebygger vil få nye
								arbeidsvinduer og nye funksjoner for å forbedre arbeidet med
								oppfølging av avvik og andre oppgaver.
								<br />
								Se artikkel for mer detaljert beskrivelse:{' '}
								<a
									href='https://norkart.zendesk.com/knowledge/articles/13518420095377/no?brand_id=360005067777'
									target='_blank'>
									Les mer...
								</a>
								<br />
								<br />
								<b>Andre nyheter så langt i år: </b>
								<ul>
									<li>
										‘Ugyldig svar’ Det er nå mulig å se hva innbygger har
										forsøkt å svare ved ‘ugyldig svar’.
										<br />
										<a
											href='https://norkart.zendesk.com/knowledge/articles/11913197955217/no?brand_id=360005067777'
											target='_blank'>
											Les mer...
										</a>
									</li>
									<li>
										Plassering for avvik, anmerkninger og andre forhold blir nå
										flettet inn i rapport ved publisering
									</li>
									<li>Forbedringer i Meglerrapporter</li>
									<li>
										Arkiv: Det er nå mulig å benytte flettefelt for
										#kommunenavn# som tittel på ny sak og journalpost
									</li>
								</ul>
							</div>

							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2022-9'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>28.11.2022 - Andelseier i borettslag</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2022-9'>
								<br />
								Det har lenge vært et tema rundt håndtering av borettslag.
								<br />
								Nå har KOMTEK Brannforebygging gjort en forbedring med å hente
								«Boretter» fra grunnboken, som lar oss hente andelseiere
								(Boretter i Borettslag) fra Andelseierregisteret, og vise
								kontaktinfo - navn, mobilnummer og eiendomsrett i
								situasjonsbildet for bruksenheten.
								<br />
								Se kundesenteret for mer info:{' '}
								<a
									href='https://komtek.zendesk.com/hc/no/articles/10839127684241-Andelseier-i-borettslag-KOMTEK-Brannforebygging'
									target='_blank'>
									Les mer...
								</a>
								<br />
							</div>

							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2022-8'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>25.10.2022 - Nyheter i Brannforebygging</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2022-8'>
								<br />
								I høst har det blitt gjort flere forbedringer i
								KOMTEK-Brannforebygging. Vi har hatt stort fokus på
								funksjonalitet mot arkiv, vedlikehold av systemet og støtte for
								forhåndsvisning av rapport før utsendelse.
								<br />
								<h4>1.Oppgradering</h4>
								Natt til fredag 21.10, slapp vi ny versjon av systemet med flere
								oppdateringer i programvaren. Oppgraderingen ble gjort for å øke
								sikkerhet, forbedre hastigheten og øke ytelsen på systemet, slik
								at vi er bedre rustet til fremtidige funksjoner. Dette var en
								nødvendig oppgradering for å sikre at systemets komponenter skal
								fungere. Det ble avdekket noe små feilsom vi ikke hadde funnet
								ved intern testing, men disse ble løst fortløpende. Ellers ser
								oppdateringenut til å være en suksess, og et viktig arbeid med
								vedlikehold av systemet er nå unnagjort.
								<br />
								<h4>2. Forhåndsvisning av rapport etter besøk</h4>
								Brannforebygging har mer eller mindre automatisert
								rapportskrivingen til saksbehandleren i dag. Måten dokumenter
								blir flettet og ferdig utfylt ut fra hva som registreres ved
								besøket, har forenklet jobben for mange, men det har også gjort
								at saksbehandleren mister litt fokus på hva som står i rapporten
								og hvordan disse dokumentmalene fungerer i forhold til hva som
								blir registrert, publisert og ikke minst arkivert.
								<br />
								I systemet vil du nå kunne bruke funksjonen «forhåndsvisning»,
								etter du startet publiseringsprosessen fra gjennomføringsbildet.
								<br />
								<br />
								<img src={eks_forh} alt='' height='200px' />
								<br />
								<br />
								Er det ingen avdekkede forhold så flettes dokumentmalen som er
								gjeldende for det utførte tiltaket, og du vil kunne se et utkast
								som har som hensikt å vise innholdet til det konkrete besøket
								som ble gjennomført.
								<br />
								Er det registrert avvik ved besøket og du huker av for å
								publisere avvik, så velges malen for «avdekkede forhold». Dette
								uavhengig av at tiltaket ble utført eller ikke utført.
								<br />
								<br />
								<img src={eks_forh2} alt='' height='200px' />
								<br />
								<br />
								Hvis det ikke er avdekkede forhold og tiltaket ikke ble utført,
								vil systemet brukemalen som er knyttet til funksjonen «ikke
								utført».
								<br />
								<br />
								<b>NB:</b>Hvis avviket berører bygget eller røykløp som er
								tilknyttet flere enheter, vil ikke forhåndsvisningen klare å
								presentere dokumenter som publiseres til andre eiere enn eiere
								av den konkrete besøksadressen. Dette vil det kunne komme støtte
								for i senere versjoner. Har du spørsmål rundt dette, kan du
								kontakte vår kundestøtte som vil kunne være behjelpelig med å
								forklare dette.
								<h4>3. Arkivintegrasjonen</h4>
								Vi har gjort forbedringer i hvordan dokumenter som produseres i
								KOMTEK-Brannforebygging blir arkivert. Dette har vi gjort for at
								arkivansvarlig skal slippe unna mest mulig etterarbeid.
								<br />
								Her er nyheter og forbedringer rundt arkivering:
								<ul>
									<li>Velge hvilke tiltakstyper som skal arkiveres</li>
									<li>Flere klasseringer med utfylling av beskrivelser</li>
									<li>Bevaringstid og kassasjonskode</li>
									<li>
										Skille på utfylling av navn for privatperson og organisasjon
									</li>
								</ul>
								<br />
								Se kundesenteret for mer info:{' '}
								<a
									href='https://komtek.zendesk.com/hc/no/articles/5545496471697-Rekkef%C3%B8lge-p%C3%A5-objekter-p%C3%A5-arbeidsliste'
									target='_blank'>
									Les mer...
								</a>
								<br />
							</div>

							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2022-7'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											20.04.2022 - Ny funksjonalitet: Planlegg og gjennomfør
											arbeidsliste i riktig rekkefølge
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2022-7'>
								<br />
								Etter at flere har begynt å "legge til objekter" i arbeidsliste
								i flere omganger, har vi sett at rekkefølgen som blir satt i
								«varsle valgte enheter» kan ende med feil oppstilling i
								arbeidslisten i forhold til det som kanskje var hensikten.
								Dermed har vi nå sluppet en ny knapp som blir å finne i
								Planlegging → Arbeidsliste som heter Rekkefølge. Her kan du
								organisere adressene i den besøksrekkefølgen som du ønsker å ta
								med videre inn i gjennomføring når du aktiverer arbeidslisten. I
								tillegg vil rekkefølgenummeret bli synlig i arbeidslisten fra
								Planlegging og Gjennomføring i kolonnen for Nr. som da står for
								rekkefølgenummeret til objektet.
								<br />
								<br />
								Se kundesenteret for mer info:{' '}
								<a
									href='https://komtek.zendesk.com/hc/no/articles/5545496471697-Rekkef%C3%B8lge-p%C3%A5-objekter-p%C3%A5-arbeidsliste'
									target='_blank'>
									Les mer...
								</a>
								<br />
							</div>

							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2022-6'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>29.03.2022 - Rediger eller slett tekstbiter for SMS</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2022-6'>
								<br />
								Du kan nå velge å slette og redigere, tidligere opprettede
								tekstbiter som brukes i forbindelse med utsending av SMS fra
								Brannforebygging.
								<br />
								<br />
								Les denne artikkelen for mer informasjon:{' '}
								<a
									/*  href='https://kundesenter.norkart.no/rediger-eller-slett-tekstbiter-for-sms/' */
									href='https://komtek.zendesk.com/hc/no/articles/5088359013009-Rediger-eller-slett-tekstbiter-for-SMS'
									target='_blank'>
									Les mer...
								</a>
								<br /> <br />
							</div>

							<br />

							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2022-5'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											15.03.2022 - Ny funksjonalitet: Sjekk status for
											utsendelse
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2022-5'>
								<br />
								For at det skal bli lettere for Brannforebyggeren å planlegge
								utsendelser av digitale varsel eller varsel via SvarUt, så har
								Brannforebygging nå fått en ny knapp som vil åpne opp for at
								planleggeren kan gjøre en sjekk mot Kontakt- og
								Reservasjonsregisteret for å finne ut hvilken metode som kan
								brukes for å varsle eier om besøk. I tillegg gjøres det en sjekk
								mot matrikkel for å se om eier har et fødselsnummer eller et org
								nr, dette for å avgjøre om eier er en person eller et foretak.
								Dermed blir det nå mulig å se hvilken metode eier skal varsles
								med (SMS, manuell-SkrivUt eller SvarUt), eller om det er
								manglende informasjon i Matrikkel som gjør at utsendelser må
								settes på vent, til riktig info er tilgjengeliggjort.
								<br />
								<br />
								Se kundesenteret for mer info:{' '}
								<a
									href='https://komtek.zendesk.com/hc/no/articles/5088438976657-Sjekk-status-for-utsendelse'
									target='_blank'>
									Les mer...
								</a>
								<br /> <br />
							</div>

							<br />
							{/*  //===================================================================== */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2022-4'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>11.03.2022 - Gratis webinar KOMTEK Brannforebygging</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2022-4'>
								<br />
								Jobber du med brannforebygging? Da er kanskje dette gratis
								webinaret noe for deg? I dette webinaret vil vi vise deg hvorfor
								KOMTEK Brannforebygging vil gjøre arbeidsdagen din bedre,
								enklere og kanskje til og med morsommere!
								<br />
								<br />
								Her er link:{' '}
								<a
									href='https://vimeo.com/682852490/fd05350163'
									target='_blank'>
									Webinar KOMTEK Brannforebygging
								</a>
								<br /> <br />
							</div>

							<br />

							{/*  //===================================================================== */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2022-3'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>15.02.2022 - Ny funksjonalitet for eier i MinEiendom</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2022-3'>
								<br />
								Fra og med i dag vil eiere få et <b>nytt valg i MinEiendom</b>.
								<br />
								Dokumenter som er publisert av Brannvesenet kan lastes ned i
								PDF-Format. <br />
								Dette gjelder dokumenter for planlegging, gjennomføring og
								oppfølging.
								<br /> <br />
								<b>Les denne artikkelen for mer informasjon:</b>
								<br />
								<a
									href='https://komtek.zendesk.com/hc/no/articles/5088605581969-Ny-funksjonalitet-for-eier-i-MinEiendom'
									target='_blank'>
									Link:
									https://kundesenter.norkart.no/ny-funksjonalitet-for-eier-i-mineiendom/
								</a>
								<br /> <br />
								<i>
									(PS: For å komme direkte til artikkelen på kundesenteret, må
									du først være innlogget før du trykker på linken)
								</i>
								<br />
								<br />
							</div>

							<br />

							{/*  //===================================================================== */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2022-2'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>02.02.2022 - Ny funksjonalitet</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2022-2'>
								<br />
								Sett ditt eget søk som <b>standard</b> for «Legg til Objekter»
								fra arbeidsliste.
								<br />
								<br />
								Vi har nå gjort det tilgjengelig for brukeren selv å definere
								sitt eget individuelle <b>«Standard søk»</b> for planlegging.{' '}
								<br />
								Nå kan brukeren selv definere hvilke kriterier som skal ligge
								til grunn i søket når objekter skal legges til arbeidslisten.{' '}
								<br />
								Dette søket kan inneholde forskjellige «Søkefilter»,
								Soner/polygon og antall visninger og valg
								<br />
								for sortering i adresselisten, og du trenger ikke lenger trykke
								noe som helst for at
								<br />
								kartet i planlegging skal vise de objektene som er aktuell for
								din planlegging.
								<br /> <br />
								<b>Les denne artikkelen for mer informasjon</b> eller kontakt
								vår kundestøtte for bistand:
								<br />
								<a
									href='https://komtek.zendesk.com/hc/no/articles/5088624000657-Lagre-standard-s%C3%B8k'
									target='_blank'>
									Link: https://kundesenter.norkart.no/lagre-standard-sok/
								</a>
								<br /> <br />
								<i>
									(PS: For å komme direkte til artikkelen på kundesenteret, må
									du først være innlogget før du trykker på linken)
								</i>
								<br />
								<br />
							</div>

							<br />

							{/*  //===================================================================== */}

							{/*               <div
                className=' collapsed'
                data-toggle='collapse'
                data-target='#2022-1'>
                <span style={{ marginLeft: '10px', fontSize: '18px' }}>
                  <strong style={{ color: '#617dad', cursor: 'pointer' }}>
                    <u>25.01.2022 - FEIL I KOMTEK Brannforebygging LØST</u>
                  </strong>
                </span>
              </div>
              <div
                style={{ marginLeft: '20px', maxWidth: '700px' }}
                className='collapse'
                id='2022-1'>
                <br />
                I går 24.01 kl 14:16 opplevde vi feil ved utsendelser av SMS i
                KOMTEK Brannforebygging. <br />
                Vi fant da senere ut at det var feil i oppslag mot ID-Porten,
                maskinporten og KRR, og at feilen lå hos DigDir.
                <br />
                Feilen ble oppdaget hos leverandør kl 14:26.
                <br />
                DigDir meldte at problemet var løst kl 18:35 .
                <br /> <br />
                <img src={feilXXX} alt='' height='400px' />
                <br />
                <br />
                Det betyr at vi er tilbake i normal drift i dag og ingen
                registrerte følgefeil av dette, utenom noen sendinger som må
                sendes på nytt.
                <br />
                <br />
                Har du spørsmål til knyttet til dette kan du kontakte
                kundestøtte via vårt kundesenter: <br />
                <a href='https://komtek.zendesk.com/hc/no/sections/4986289245585-Brannforebygging' target='_blank'>
                  Kundesenter
                </a>
                <br /> <br />
              </div>

              <br /> */}
							{/*  //===================================================================== */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-20'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>01.12.2021 - SvarUt er nå tilgjengelig</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2021-20'>
								<br />
								Ønsker du tilbud eller er du usikker på hva du må gjøre for å få
								SvarUt, <br />
								kan du kontakte vår kundestøtte.
								<br />
								<br />
								Mer informasjon<b> og video</b>:{' '}
								<a
									href='https://komtek.zendesk.com/hc/no/articles/5088789524881-SvarUt-Brannforebygging '
									target='_blank'>
									SvarUt - Brannforebygging
								</a>
								<br /> <br />
							</div>

							<br />
							{/*  //===================================================================== */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-19'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>25.11.2021 - Ny versjon av oppfølging Åpne Avvik</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2021-19'>
								<br />
								Oppgraderingen vil skje automatisk og går i full effekt fra
								Fredag 26.11. <br />
								Nye endringer i versjonen innebærer at du nå ser alle avvik
								samlet pr bruksenhet når saksbehandler velger ett av avvikene i
								oppfølging.
								<br /> <br />
								Se mer informasjon:{' '}
								<a
									href='https://komtek.zendesk.com/hc/no/articles/5088941793809-Oppf%C3%B8lging-av-avvik-pr-Bruksenhet '
									target='_blank'>
									Oppfølging av avvik per Bruksenhet
								</a>
								<br /> <br />
							</div>

							<br />

							{/*  //===================================================================== */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-18'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											23.11.2021 - Gratis Webinar KOMTEK Brannforebygging SvarUt
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px', maxWidth: '700px' }}
								className='collapse'
								id='2021-18'>
								<br />
								KOMTEK Brannforebygging SvarUt gir deg en enda bedre mulighet
								for dialog mellom brannforebyggeren og eieren av bruksenheten.
								<br />
								En effektiv kommunikasjon gir grunnlaget for mer effektiv
								planlegging, gjennomføring og oppfølging. Man bruker egne
								dokumentmaler med flettefelt som knyttes til ulike tiltakstyper,
								slik at riktig mal velges automatisk. Systemet bruker Kontakt-
								og Reservasjonsregisteret for å finne telefonnummer, og
								matrikkelen brukes for å finne adresser til eiere.
								<br />
								Kommunikasjonen mellom KOMTEK Brannforebygging og eiere av
								bruksenhetene bruker flere kanaler, som SMS, Innbyggerdialog/Min
								eiendom og Arkiv. <b>KOMTEK Brannforebygging SvarUT</b> lar deg
								sende dokumenter via KS SvarUt i alle arbeidsprosesser. I
								tillegg har vi nå forbedret oppfølging av avvik. Nå kan man
								følge opp avvik samlet eller enkeltvis for hver bruksenhet.
								Dette vil gjøre det enklere å få oversikt over bruksenheten.
								<br />
								<br />
								Vi inviterer til <b>
									webinar torsdag 25. nov kl 1300-1400{' '}
								</b>{' '}
								for en gjennomgang av hva dette er og hvordan du tar det i bruk.
								<br />
								<br />
								Påmelding:{' '}
								<a
									href='https://www.norkart.no/kurs/fakturering-komtek-2-2-2/'
									target='_blank'>
									Webinar KOMTEK Brannforebygging SvarUt
								</a>
								<br /> <br />
							</div>

							<br />
							{/*  //===================================================================== */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-17'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>15.11.2021 - Rapport Gebyr</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-17'>
								<div>
									<br />
									<h3 style={{ float: 'left' }}>Ny rapport gebyr</h3>
									&nbsp;&nbsp;&nbsp;
									<img
										src={FagKronGreen}
										alt=''
										height='24px'
										minWidth='24px'
										width='24px'
									/>
									&nbsp;
									<img
										src={FagKronYellow}
										alt=''
										height='24px'
										minWidth='24px'
										width='24px'
									/>
									&nbsp;
									<img
										src={FagKronRed}
										alt=''
										height='24px'
										minWidth='24px'
										width='24px'
									/>
								</div>
								<br />
								<br />
								For de som har gebyrkobling i KOMTEK Brannforebygging mot KOMTEK
								Forvaltning, så har det nå kommet en ny gebyrrapport.
								<br />
								Rapporten er ment for å avdekke avvik mellom fagdata i
								Brannforebygging og gebyret på avtale i KOMTEK Forvaltning og
								dermed være med på å heve datakvaliteten.
								<br />
								<br />
								For mer informasjon:
								<br />
								<a href='https://komtek.zendesk.com/hc/no/articles/5108017272849-Rapport-for-feie-og-tilsynsgebyr-i-Brannforebygging '>
									https://kundesenter.norkart.no
								</a>
							</div>
							<br />

							{/*  //===================================================================== */}

							{/*  //===================================================================== */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-16'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											02.11.2021 - Ny Funksjonalitet – Registrere «Andre
											forhold»
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-16'>
								<h3>Ny Funksjonalitet – Registrere «Andre forhold»</h3>
								<br />
								Vi har nå lagt til rette for at man, i tillegg til Anmerkninger
								og avvik,
								<br />
								kan registrere forhold som «Andre forhold» i KOMTEK
								Brannforebygging.
								<br />
								<br />
								For mer informasjon:
								<br />
								<a
									href='https://komtek.zendesk.com/hc/no/articles/5089100277777-Registrere-Andre-forhold-'
									target='_blank'>
									https://kundesenter.norkart.no
								</a>
							</div>
							<br />

							{/*  //===================================================================== */}

							{/*            <div
                className=' collapsed'
                data-toggle='collapse'
                data-target='#2021-15'>
                <span style={{ marginLeft: '10px', fontSize: '18px' }}>
                  <strong style={{ color: '#617dad', cursor: 'pointer' }}>
                    <u>05.10.2021 - Feil i KOMTEK Brannforebygging - Løst</u>
                  </strong>
                </span>
              </div>
              <div
                style={{ marginLeft: '20px' }}
                className='collapse'
                id='2021-15'>
                <h3>Feil i Brannforebygging er løst</h3>
                <br />
                Vi ble oppmerksom på at det feilet ved «sendt til eier» fra
                gjennomføring i dag tidlig ca. kl 08:00.
                <br />
                Feilen var i forbindelse med generering av PDF dokumenter.
                <br />
                Dermed fikk noen kunder oppleve en feilmelding som sa at noe
                feilet ved arkivering.
                <br />
                Feilen er nå rettet og PDF-generatoren var operativ igjen litt
                over kl. 10:00.
                <br />
                Beklager ulempene dette har medført. Dersom du var berørt av
                denne feilen, kan du nå prøve sende på nytt, fra
                gjennomføringslisten.
                <br />
                Opplever du fortsatt feil, ta kontakt med KOMTEK@norkart.no
                <br />
                <br />
                Med vennlig hilsen
                <br />
                Norkart AS
                <br />
              </div>
              <br /> */}

							{/*  //===================================================================== */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-14'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>30.09.2021 - Brukerundersøkelse Brannforebygging</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-14'>
								<h3>Brukerundersøkelse Brannforebygging</h3>
								<br />
								Vi ønsker å forbedre KOMTEK Brannforebygging. Derfor er det
								viktig med regelmessige tilbakemeldinger fra dere som bruker
								det.
								<br />
								<br />
								I løpet av oktober starter vi med brukerundersøkelser. Cirka en
								gang i måneden stiller vi deg noen korte spørsmål om KOMTEK
								Brannforebygging. <br /> Vi håper å kunne bruke svarene til å
								gjøre KOMTEK Brannforebygging til et enda bedre produkt.
								<br />
								<br />
								Undersøkelsen er anonym.
								<br />
								<br />
							</div>
							<br />

							{/* ************************ */}

							{/* ********************* */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-13'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>28.09.2021 - Fyringsforbud</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-13'>
								<div>
									<h3 style={{ float: 'left' }}>
										Visning av objekter som er underlagt fyringsforbud{' '}
									</h3>
									&nbsp;&nbsp;&nbsp;
									<img src={Forbud} alt='' height='36px' />
								</div>
								<p />
								<br />
								Det er nå mulig å registrere fyringsforbud. Dette kan foreløpig
								gjøres i situasjonsbildet → Avvik/Anmerkninger.
								<br />
								<br />
								<img src={fyring2} alt='' height='200px' />
								<br />
								<br />
								I 'Planlegging → LeggTilObjekter' og i 'Objekter → Søk på
								enheter'
								<br />
								blir enheter som er underlagt fyringsforbud <br /> markert med
								rød / grå og med en svart markør:
								<br />
								<br />
								<img src={pollux} alt='' height='36px' /> &nbsp;&nbsp;&nbsp;
								<img src={Forbud} alt='' height='36px' />
								<br />
								<br />
								Dette er en funksjon som skal gi brannforebyggerne oversikt over
								bruksenheter med fyringsforbud,
								<br />
								og dermed kunne unngå å planlegge besøk og varsle bruksenheter
								som er underlagt fyringsforbud <br />
								fra planlegging. Disse bruksenhetene vil også være markert i
								"søk på enheter".
								<br />
								<br />
							</div>
							<br />

							{/* ************************ */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-12'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											23.09.2021 - Opptak Webinar KOMTEK Brannforebygging og
											gebyr
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-12'>
								<div>
									<br />
									<h3 style={{ float: 'left' }}>
										Opptak Webinar KOMTEK Brannforebygging – nye rutiner for
										gebyrføring
									</h3>
									&nbsp;&nbsp;&nbsp;
									<img
										src={FagKronGreen}
										alt=''
										height='24px'
										minWidth='24px'
										width='24px'
									/>
									&nbsp;
									<img
										src={FagKronYellow}
										alt=''
										height='24px'
										minWidth='24px'
										width='24px'
									/>
									&nbsp;
									<img
										src={FagKronRed}
										alt=''
										height='24px'
										minWidth='24px'
										width='24px'
									/>
								</div>
								<br />
								<br />
								Opptaket av webinaret ser du her:
								<a
									href='https://register.gotowebinar.com/recording/6607870687120719619'
									target='_blank'>
									«KOMTEK Brannforebygging – nye rutiner for gebyrføring»
								</a>
								<br />
								<br />
							</div>
							<br />
							{/* ********************* */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-11'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											14.09.2021 - Gratis Webinar KOMTEK Brannforebygging og
											gebyr
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-11'>
								<div>
									<br />
									<h3 style={{ float: 'left' }}>
										Gratis Webinar KOMTEK Brannforebygging – nye rutiner for
										gebyrføring
									</h3>
									&nbsp;&nbsp;&nbsp;
									<img
										src={FagKronGreen}
										alt=''
										height='24px'
										minWidth='24px'
										width='24px'
									/>
									&nbsp;
									<img
										src={FagKronYellow}
										alt=''
										height='24px'
										minWidth='24px'
										width='24px'
									/>
									&nbsp;
									<img
										src={FagKronRed}
										alt=''
										height='24px'
										minWidth='24px'
										width='24px'
									/>
								</div>
								<br />
								<br />
								Torsdag 16.september kl. 1200 – 1300 inviterer vi til webinar
								«KOMTEK Brannforebygging – nye rutiner for gebyrføring».
								<br />
								<br />
								<b>I webinaret blir vi bedre kjent med:</b>
								<ul>
									<li>
										Hvordan brannforebygger kan få innsyn i feie/tilsynsgebyrene
										slik at man kan kontrollere om det er samsvar mellom fagdata
										og gebyr,
										<br /> og hvordan man kan melde fra til gebyransvarlig
										dersom gebyrene skal korrigeres i KOMTEK Forvaltning{' '}
									</li>
									<li>
										Hvordan man kan rapportere på avvik mellom fagdata i
										Brannforebygging og gebyrene i KOMTEK Forvaltning slik at
										man kan ta tak
										<br /> i disse avvikene og rette dem opp
									</li>
									<li>
										Hvordan feieren og gebyransvarlig kan etablere rutiner for å
										øke datakvaliteten av koblingen mellom fagdata og gebyrene{' '}
									</li>
								</ul>
								<br />
								Dette webinaret henvender seg til både bruker av KOMTEK
								Brannforebygging
								<br /> og til den som har ansvaret for gebyrføringen av disse
								gebyrene i KOMTEK.
								<br />
								<br />
								Du kan ikke lenger registrere deg.
								{/*  <a
                  href='https://register.gotowebinar.com/register/5083404411959788044/'
                  target='_blank'>
                  «KOMTEK Brannforebygging – nye rutiner for gebyrføring»
                </a> */}
								<br />
								<br />
							</div>
							<br />

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-10'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>09.07.2021 - Notater</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-10'>
								<h3>NY FUNKSJONALITET – NOTATER</h3>
								I Gjennomføring - Situasjon kan man legge inn notater.
								<br />
								Nå er det også mulig å slette notater.
								<br />
							</div>
							<br />
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-9'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>17.06.2021 - SMS</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-9'>
								<h3>
									NY FUNKSJONALITET – NÅ KAN DU SENDE SMS FRA PLANLEGGING OG
									SITUASJONSBILDET
								</h3>
								Det har hittil kun vært mulig å sende SMS via
								varslingsveiviseren og de automatiserte rutinene i KOMTEK
								Brannforebygging.
								<br />
								Vi har nå laget funksjonalitet for å sende massiv SMS fra
								planleggingsbildet til markerte eiere i listen.
								<br />
								Brukere må være klar over at dette ikke vil oppdatere
								varselstatusen i listene, men vil kunne brukes der man ønsker å
								sende
								<br />
								informasjon utenom den ordinære varslingen.
								<br />
								I tillegg ligger samme muligheten på context-menyen for
								bruksenhet i situasjonsbildet. Her kan du sende enkeltmeldinger
								til
								<br />
								eieren av eiendommen. SMS meldingen vil logges under
								innbyggerdialogen.
								<br />
							</div>
							<br />
							{/* ********************* */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-8'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>09.06.2021 - Edge</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-8'>
								<h3>BRANNFOREBYGGING KAN ÅPNES I EDGE</h3>
								Brannforebygging kan nå også brukes i Edge. <br />
								<br />
							</div>
							<br />

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-7'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>21.04.2021 - Skrive ut varsel</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-7'>
								<h3>SKRIVE UT VARSEL</h3>
								Brannforebygging har nå åpnet for å skrive ut varsel til kunder{' '}
								<br />
								som, av ulike årsaker, ikke får varslet digitalt. <br />
								<br />
								<p>
									<span
										style={{
											float: 'left',
											width: '110px',
											marginRight: '40px',
										}}>
										<img src={skrivvarsel} alt='' />
									</span>
									<br />
									For å skrive ut et varsel, kan du (i planlegging) åpne <br />
									arbeidslista og klikke på menyen til høyre for bruksenheten og{' '}
									<br />
									velg <b>Skriv ut</b>. Først åpnes en dialogboks hvor du må
									bekrefte <br />
									dato (og evt klokkeslett). Da åpner en dialog med ferdig{' '}
									<br />
									varselbrev. Dette er det samme brevet som kunder kan se på Min{' '}
									<br />
									eiendom når man har fått digitalt varsel. Altså: Klargjør{' '}
									<br />
									dokumentmal for varsel. Klikk på printer-ikonet når du er{' '}
									<br />
									fornøyd med varselet.
								</p>
								<p />
								<p />
								<br />
								<br />
							</div>
							<br />
							{/* ********************* */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-5'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>15.04.2021 - SvarUt.</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-5'>
								<h3>SVARUT </h3>
								Brannforebygging har nå støtte for SvarUT i planlegging og
								gjennomføring.
								<br />
								Det betyr at man kan sende dokumenter via Svar Ut ved varsling
								av tiltak, og ved lagring av utførte tiltak.
								<br />
								Dokumentene vil produseres til eiere som ikke er registrert i
								Kontakt- og reservasjonsregisteret.
								<br />
								Det er laget mange flettefelt som kan legges inn i malene i alle
								hovedfunksjoner.
								<br />
								Det er også laget funksjonalitet for enkelt å legge inn
								eksempelmaler fra Norkart hvis man ønsker dette.
								<br />
								Funksjonaliteten er beskrevet i vedlagte dokument, og
								instruksjonsvideoer finnes på kundesenteret.
								<br />
								<a href={dokumentasjon} download>
									Last ned dokument..
								</a>
								<br />
								<a
									href='https://komtek.zendesk.com/hc/no/articles/5088789524881-SvarUt-Brannforebygging'
									target='_blank'>
									Norkart Kundesenter instruksjonsvideoer..
								</a>
								<br />
								Dersom de ønsker bistand til oppsett av maler, kan man kontakte
								konsulentavdelingen{' '}
								<a href='mailto:jan.alfstad@norkart.no'> v/Jan Erik Alfstad</a>.
								<br />
								<br />
								<br />
							</div>
							<br />
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-4'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>01.04.2021 - Risikoanalyse forbedret</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-4'>
								<h3>RISIKOANALYSE FORBEDRET</h3>
								Antall kunder som har tatt ibruk Risikovurdering øker stadig.
								<br />
								Som en følge av dette har Norkart nå flyttet analysemotoren over
								til en skybasert plattform - FME Cloud.
								<br /> Dette betyr at motoren har mye større kapasitet. Nå skal
								ingen av våre kunder oppleve å stå i kø for å få tilbake
								resultatet fra risikoanalysen.
								<br />
								Risikotallet blir beregnet, og hyppighet og dato for når neste
								tiltak skal utføres oppdateres direkte.
								<br />
							</div>
							<br />
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-3'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											01.04.2021 - Problem med at bruker blir kastet ut løst
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-3'>
								<br />
								<h3>Problem med at bruker blir kastet ut løst</h3>
								Problem med at bruker blir kastet ut av Brannforebygging er nå
								løst.
								<br />
								<br />
								Vi får av og til meldinger om at noen brukere må logge seg inn
								flere ganger på en dag. Dette har vi nå forbedret.
								<br />
								Husk at det forsatt er å anbefale å logge seg ut dersom man ikke
								skal bruke applikasjonen lenger. Dette ifm. sikkerhet.
								<br />
								<br />
							</div>
							<br />

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021-2'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>01.04.2021 - Ny funksjonalitet – Filter: Feieadkomst</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021-2'>
								<h3>NY FUNKSJONALITET – FILTRERE PÅ FEIEADKOMST</h3>
								Det er nå åpnet for å kunne filtrere på Feieadkomst.
								<br />
								<br />
								I koronaperioden har det meldt seg et behov for å kunne filtrere
								på Feieadkomst. Dette er nå tilgjengelig både i Søk på objekter
								og i Planlegging.
								<br /> <br />
								<img src={Feieadkomst} alt='' height='400px' />
								<br />
								<br />
								<br />
							</div>
							<br />

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#2021_1'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											21.01.2021 - Ny funksjonalitet – Presentasjon av
											nøkkeltall
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='2021_1'>
								<h3>NY FUNKSJONALITET – NØKKELTALL</h3>
								<br />
								<img src={Nokkeltall} alt='' height='240px' />
								<br />
								<br />
								<p
									style={{
										padding: '0',
										maxWidth: '800px',
									}}>
									I tiden etter nyttår har mange av våre kunder etterlyst
									nøkkeltall i forbindelse med rapportering til DSB, i tillegg
									til avstemming av fagdata mot gebyr. I den forbindelse har
									noen valgt å benytte søkefiltrene i systemet. Disse gir ikke
									nødvendigvis de tallene som blir etterspurt, fordi noen av
									disse viser resultatet i bruksenheter, mens andre viser
									røykløp. Filtersøkene er ikke spisset mot statistikk, men skal
									finne objekter ved å filtrere bort visse fagegenskaper. Dette
									gjør det lett å mistolke tall som vises i filtrene.
									<br />
									<br />
									Behovet for å dokumentere antall røykløp, ildsteder, og tiltak
									er nå løst ved at vi har laget en ny funksjonalitet for dette.
									Denne funksjonaliteten viser alle røykløp som er registrert i
									systemet, fordelt på de er i bruk eller ikke. Det er også
									visning av antall registrerte Ildsteder fordelt på samme måte.
									I tillegg kan man se alle registrerte tiltak i systemet, enten
									de er registrert som utført eller ikke.
									<br />
									Du kan enkelt foreta et valg om dato 'fra' eller 'til' for å
									justere resultatene av disse analysene. Hvis organisasjonen
									din består av flere kommuner, kan du skille analysene på hver
									kommune.
									<br />
									<br />
									I tillegg har vi laget grafiske visninger av resultatene.
									Disse kan enkelt kopieres eller skrives ut for videre bruk i
									dokumentasjon eller publikasjoner.
									<br />
									<br />
									Funksjonaliteten ligger i hovedmenyen under{' '}
									<u>Rapporter &#8594; Nøkkeltall</u>. <br />
									Vi har lagt inn fire analyser, og håper disse er til nytte for
									dere.
								</p>
								<br />
							</div>
							<br />

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#181220202'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>18.12.2020 - Ny funksjonalitet i Etabler fagdata </u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='181220202'>
								<h3>NY FUNKSJONALITET I ETABLER FAGDATA</h3>
								Etabler fagdata er nå utstyrt med flere sorteringsmuligheter og
								ny kolonne for å vise Bygningsstatus.
								<br />
								<br />
								<img src={Eta1} alt='' height='160px' />
								<br />
								<br />
								1. Du kan nå sortere listen etter
								<ul>
									<li>Dato opprettet </li>
									<li>Sorter på bruksenhetstype alfabetisk fra (a-å)</li>
									<li>Sorter på bruksenhetstype alfabetisk fra (å-a)</li>
									<li>Sorter alfabetisk på adresse </li>
								</ul>
								<img src={Eta2} alt='' height='160px' />
								<br />
								<br />
								2. Du kan vise eller skjule unummererte bruksenheter
								<br />
								<img src={Eta3} alt='' height='80px' />
								<br />
								<br />
								3. Du kan se bygningsstatus for bruksenheten i egen kolonne i
								listen.
								<br />
								<br />
								<img src={Eta4} alt='' height='160px' />
								<br />
								<br />
								{/* ********************* */}
							</div>
							<br />
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#10003'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											23.11.2020 - Støtte for håndtering av unummererte
											bruksenheter
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='10003'>
								<h3>STØTTE FOR HÅNDTERING AV UNUMMERERTE BRUKSENHETER</h3>
								<a
									href='https://komtek.zendesk.com/hc/no/articles/6651215320209-St%C3%B8tte-for-h%C3%A5ndtering-av-unummererte-bruksenheter'
									target='_blank'>
									Se også artikkel på kundesenter
								</a>

								<p
									style={{
										padding: '0',
										maxWidth: '800px',
									}}>
									<b>
										<u>Bakgrunn</u>
									</b>
									<br />
									Komtek Brannforebygging benytter matrikkeldata for etablering
									av objekter. Røykløp og ildsteder registreres på bruksenheter
									direkte fra matrikkelen. Vi har satt en begrensning på hvilke
									bruksenheter vi ønsker å vise for å gjøre systemet mer
									oversiktlig.
									<br />
									<br />
									Følgende kriterier har hittil vært lagt til grunn for visning
									av bruksenheter fra matrikkelen:
									<ul>
										<li>
											Bruksenhet skal IKKE vises hvis bruksenhetstypekode 'U'
											(Unummerert){' '}
										</li>
										<li>
											Bruksenhet skal IKKE vises hvis tilhørende
											bygningsstatuskode er en av disse: (‘RA’, 'BU', 'BF',
											'BA', 'BR')
										</li>
										<li>
											Bruksenhet skal IKKE vises hvis bruksenheten er knyttet
											til bygningsnummer med løpenr som ikke er NULL
										</li>
									</ul>
									<br />
									Det henvises til kartverkets føringsinstruks for matrikkelen
									for en nærmere forklaring på hva hver enkelt av disse
									begrepene og kodene betyr. Det har imidlertid kommet
									tilbakemeldinger fra kunder med ønske om at Brannforebygging
									skal kunne tillate etablering av fyringsanlegg på unummererte
									bruksenheter.
									<br />
									<br />
									<u>
										Det er i denne sammenheng viktig at man er klar over
										følgende informasjon fra føringsinnstruksen i matrikkelen,
										før man begynner å etablere fyringsanlegg på disse
										bruksenehetene:{' '}
									</u>
									<br />
								</p>
								<p
									style={{
										padding: '10px',
										backgroundColor: 'lightgray',
										maxWidth: '800px',
									}}>
									&quot;Unummerert bruksenhet&quot; brukes til å ta vare på
									kobling mellom bygning - matrikkelenhet og adresse, og som
									ikke ligger på en vanlig bruksenhet (Bolig, Ikke godkjent
									bolig, Fritidsbolig, Annet enn bolig). Ved føring av for
									eksempel frittstående uthus, garasjer og liknende bygg,
									benyttes betegnelsen unummerert bruksenhet for å knytte
									bygningen til aktuell matrikkelenhet og eventuell adresse.
									Benyttes der det ikke er nødvendig å registrere bygningen med
									eget bruksenhetsnummer. Unummerert bruksenhet kan også
									benyttes der garasjer/garasjeanlegg er seksjonert, spesielt
									der dette er tilknyttet et seksjonert boligsameie.
								</p>
								<br />
								<p
									style={{
										padding: '0',
										maxWidth: '800px',
									}}>
									<b>
										Det oppfordres herved til ikke å knytte fyringsanlegg
										ukritisk til bruksenheter som er registrerte som
										unummererte, men heller etterstrebe å korrigere matrikkelen
										i de tilfeller bruksenheten er feil.{' '}
									</b>
									<br />
									<br />
									Utover dette finnes det noen få eksempler på garasjer, løer,
									båtnaust og lignende som ligger unummererte i matrikkelen, der
									det faktisk er behov for å registrere et fyringsanlegg. Det er
									dette vi nå har lagt til rette for med denne oppdateringen.
									<br />
									<br />
									<u>Endringer som er gjort:</u>- Vi har gjort det mulig å vise
									unummererte bruksenheter i alle våre søk:
									<ul>
										<li>Planlegging – Arbeidsliste – Legg til objekter </li>
										<li>Objekter – Søk på enheter</li>
										<li>Objekter – Etabler fagdata </li>
										<li>Objekter – Fiktive enheter - Koble til bruksenhet</li>
									</ul>
									- Vi har endret visning for innbyggeren i Min Eiendom, slik at
									bruksenheter som har er registrert med fagdata i
									Brannforebygging kan vises selv om de er unummererte
									<br />
								</p>
								<br />
								<u>Beskrivelse av hvordan søket fungerer</u>
								<br />
								<p
									style={{
										padding: '0',
										maxWidth: '800px',
									}}>
									Ved åpning/visning av disse vinduene kjøres en spørring som
									henter alle bruksenheter, også de som er ‘unummerert’, fra
									matrikkelen. Deretter settes automatisk et søkefilter:{' '}
									<b>‘Skjul unummererte bruksenheter’</b> som vist i bildet
									under. Dette har vi gjort fordi vi mener det er mest
									hensiktsmessig at utvalget som standard vises fremdeles
									utelater unummererte bruksenheter, slik det gjorde før fagdata
									på unummererte bruksenheter ble mulig. Når man slår av
									filteret <b>‘Skjul unummererte bruksenheter’</b> vises alle
									unummererte bruksenheter fra matrikkelen.
								</p>

								<br />
								<img src={num1} alt='' height='280px' />
								<br />
								<br />
								<img src={num2} alt='' height='200px' />
								<br />
								<br />

								<br />
								<hr style={{ height: '1px', background: 'gray' }} />
								<br />
								<table>
									<tbody>
										<tr>
											<td style={{ padding: '6px', maxWidth: '500px' }}>
												Dersom man allerede har lagret søkefilter for senere
												bruk, bør man endre disse ved å legge til ‘Skul
												unummererte bruksenheter’ hvis man ønsker dette.
											</td>
											<td>
												<img src={num3} alt='' height='180px' />
											</td>
										</tr>
									</tbody>
								</table>
								<br />
								<hr style={{ height: '1px', background: 'gray' }} />
								<br />
								<table>
									<tbody>
										<tr>
											<td style={{ padding: '6px', maxWidth: '500px' }}>
												Resultatlisten er nå utvidet med en kolonne
												‘Bruksenhetstype’, slik at man lett kan se hvilke
												bruksenheter som er unummererte.
											</td>
											<td>
												<img src={num4} alt='' height='200px' />
											</td>
										</tr>
									</tbody>
								</table>
								<br />
								<p
									style={{
										padding: '0',
										maxWidth: '800px',
									}}>
									For unummererte bruksenheter der fagdata er etablert vil det
									nå være mulig å planlegge, utføre og følge opp tiltak. Disse
									bruksenhetene vil som vist bli tilgjengelig i planlegging når
									filterkriteriene settes slik at de kommer frem i søkeresultat.
									<br />
									For eiere vil kommunikasjon som gjelder unummererte
									bruksenheter oppleves på samme måte som for andre typer
									bruksenheter og de unummererte bruksenheter med fagdata vil
									fremkomme i Min Eiendom.
								</p>
							</div>
							<br />
							{/* ********************* */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#10002'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>12.11.2020 - Forbedret varselveiviser</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='10002'>
								<h3>FORBEDRET VARSELVEIVISER</h3>
								Det er nå gjort justeringer i varselveiviseren, hvor man velger
								tidsmodus før man legger til planlagt tid i varselveiviseren.
								Noen knapper som ikke er hensiktsmessige for enkelte tidsmoduser
								er fjernet og kun tilegnet de modusene hvor disse knappene har
								en nyttig funksjon. Se instruksjonsvideo for å bli geleidet
								gjennom ny versjon ved å trykke{' '}
								<a
									href='https://vimeopro.com/norkart/komtek-brannforebygging'
									target='_blank'>
									her.
								</a>
								<br />
							</div>
							<br />
							{/* ********************* */}

							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#08112020'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>08.11.2020 - Ny skrivepanel</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='08112020'>
								<h3>NY FUNKSJONALITET – NY SKRIVEPANEL</h3>
								Vi bruker nå en ny og forbedret skrivepanel: Froala. Dette ser
								du blant annet på disse sidene:
								<ul>
									<li>Innstillinger → Mal oppsett → Dokumentmaler </li>
									<li>Oppfølging → Åpne avvik → Nytt svar </li>
									<li>
										Og snart på enda flere sider hvor man kan redigere tekster.{' '}
									</li>
								</ul>
								<br />
								<img src={froala1} alt='' width='33%' height='33%' />
								<br />
								<br />
								Undermenyer åpnes ved å klikke på knappene med 3 punkt.
								<br />
								<br />
								<img src={froala2} alt='' width='33%' height='33%' />
								<br />
							</div>
							<br />
							{/* ********************* */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#11052020'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>11.05.2020 - Kvitteringsmelding ved toveis SMS</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='11052020'>
								<h3>
									NY FUNKSJONALITET – KVITTERINGSMELDING TIL INNBYGGER VED BRUK
									AV TOVEIS SMS
								</h3>
								For de kunder som benytter toveis sms ved varsling, har vi lagt
								inn mulighet for å sende kvitteringsmelding til disse
								automatisk. Funksjonaliteten må aktiveres, og inneholder 3 sms
								maler som må verifiseres.
								<ul>
									<li>Mal 1: Varsel kvittering gyldig svar</li>
									Denne sendes dersom innbygger har svart 1 eller 2 på
									varselmeldingen, og verifiserer at brannvesenet har mottatt
									beskjeden.
									<li>Mal 2: Varsel kvittering ugyldig svar</li>
									Denne sendes dersom innbygger har svart noe annet 1 eller 2 på
									varselmeldingen. Meldingen påpeker at svar er ugyldig, og
									innbygger gis mulighet til å svare på nytt.
									<li>Mal 3: Varsel kvittering allerede svart</li>
									Hvis innbygger allerede har svart på varselet, får man denne
									meldingen hvis man forsøker å sende en gyldig eller ugyldig
									melding på nytt.
								</ul>
								<br />
								Ta kontakt med NORKART for bistand til å aktivere denne
								funksjonaliteten.
								<br />
								<br />
							</div>
							<br />
							{/* ********************* */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#17032020'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>17.03.2020 - Ny funksjonalitet</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='17032020'>
								<h3>
									NY FUNKSJONALITET VIL BLI AKTIVERT HOS DERE I DEN NÆRMESTE
									TIDEN
								</h3>
								I disse dager jobber vi med utrulling av ny funksjonalitet for
								våre Brannforebyggingsbrukere. Det du kan forvente å få levert
								følgende:
								<ul>
									<li>
										Varsling (toveis SMS) hvor innbygger kan svare med tallet 1
										for at planlagt tiltak passer eller 2 for å få ny avtale.
									</li>
									<li>
										Det blir nytt mal oppsett med flere flettefelt for å
										forenkle tilpassing av SMS utsendelser.
									</li>
									<li>
										En helt ny varselveiviser med nye muligheter for å
										organisere arbeidsliste i nummerert rekkefølge ut fra liste
										eller kart.
									</li>
									<li>
										Funksjonalitet for kanselering av varsel og planlagte
										tiltak, med mulighet til å registrere begrunnelse.
									</li>
									<li>
										Det blir mulig for dere å aktivere automatisk påminnelse ca
										24 timer før besøk.
									</li>
									<li>
										Det blir også mulig for dere å aktivere automatisk purring
										på utelatt tilbakemelding på varsel om tilsyn eller feiing.
									</li>
								</ul>
								<br />
								Er det noe dere lurer på i den forbindelse kan dere kontakte vår
								kundestøtte.
								<br />
								<br />
							</div>
							<br />
							{/* ************************* */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#03012020'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											03.01.2020 - Om regionreform og kommunenummer endring 2
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='03012020'>
								<h3>
									NY INFORMASJON ANGÅENDE GJENNOMFØRINGEN AV REGIONREFORM –
									KOMMUNENUMMERENDRING I KOMTEK BRANNFOREBYGGING
								</h3>
								Riktig godt nyttår til alle våre brukere av KOMTEK
								Brannforebygging!
								<br />
								<br />
								Viser til driftsmeldingen som ble utsendt 17.12 at
								Brannforebygging skulle være stengt frem til tirsdag 7. Januar,
								og kan nå komme med oppdatert informasjon om status for
								kommunereform og kommunenummerendring som har berørt brukere av
								KOMTEK Brannforebygging.
								<br />
								<br />
								Kommunesammenslåing og fylkessammenslåing er nå gjennomført og
								vi kan med glede og stolthet melde at denne fasen har gått
								veldig bra. Vi har nå testet i prod- og testbasen, vi har
								verifisert at data stemmer med matrikkel og med dette så åpner
								vi dørene til bruk av Brannforebygging med umiddelbar virkning.
								<br />
								<br />
								Vi vil gjøre dere oppmerksom på at det kan oppstå følgefeil, vi
								ber dere derfor være forberedt på dette, og at dere gir oss
								informasjon om dette umiddelbart. Vi ber dere om at dere
								planlegger feiing/tilsyn på en kjent adresse og at dere sender
								varsel som dere kan svare på. Verifiser at dere får logget på
								Min Eiendom og at data blir oppdatert i KOMTEK Brannforebygging
								og arkiv etter utført tiltak.
								<br />
								<br />
								Feil eller spørsmål skal meldes til kundestøtte og behandles av
								fagteamet for Brannforebygging slik at dere får best oppfølging.
								<br />
								<b>NB: </b> Du kan finne nyeste informasjon på vårt kundesenter:
								<a
									href='https://komtek.zendesk.com/hc/no/sections/4986289245585-Brannforebygging'
									target='_blank'>
									www.nkgs.no/wip4/kundesenter/
								</a>
								<br />
								<span style={{ fontSize: '14px' }}>
									(Her er det viktig at du har bruker og passord som du får ved
									å kontakte oss. Husk å endre passordet ditt etter på)
								</span>
								<br />
								<br />
								<br />
								<span style={{ fontSize: '18px' }}>
									Kontakt din kundestøtte:
								</span>
								<br />
								<br />
								<b>E-post: </b> komtek@norkart.no
								<br />
								<b>Telefon: </b>67 48 38 88 (08:30 – 15:30)
								<br />
								<br />
							</div>
							{/* ************************* */}
							<br />
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#16122019'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>
											16.12.2019 - Om regionreform og kommunenummer endring 1
										</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='16122019'>
								<h3>
									VIKTIG INFORMASJON OM GJENNOMFØRING AV REGIONREFORM –
									KOMMUNENUMMERENDRING I KOMTEK BRANNFOREBYGGING
								</h3>
								Prosjekt «Kommune og regionreform» er inne i gjennomføringsfasen
								der konkret arbeid skal utføres på felles datasett, verktøy og
								tjenester. <br />
								Denne informasjonen går ut til alle kommuner og IKS som har
								KOMTEK Brannforebygging i bruk i produksjonsmiljø og testmiljø.
								<br />
								<br />
								<div style={{ padding: '20px', backgroundColor: 'powderBlue' }}>
									<label>
										I perioden fra onsdag 1. januar 2020 kl 00.00 tom. tirsdag
										7. januar 2020 kl 06.00 stenges KOMTEK Brannforebygging,
										dette medfører at systemet ikke kan brukes.
									</label>
								</div>
								<br />
								<b>Årsaken til stengingen er regionreformen.</b>
								<ul>
									<li>
										Pr 1.1.2020 blir kommunesammenslåing og fylkessammenslåing
										gjennomført.
									</li>
									<li>
										Fylkessammenslåing medfører endring i kommunenummer for de
										fleste kunder av KOMTEK Brannforebygging.
									</li>
									<li>
										Dette gjelder kommuner og IKS i produksjons- og testmiljø.
									</li>
									<li>
										Statens Kartverk vil legge ut den nye matrikkelen pr
										1.1.2020.
									</li>
									<li>
										Norkart skal laste ned en kopi av nye matrikkelen natt til
										1. januar.
									</li>
									<li>
										Nedetid for KOMTEK Brannforebygging blir begrenset til
										perioden onsdag 1. januar 2020 kl 00.00 tom tirsdag 7.
										januar 2020 kl 06.00.
									</li>
									<li>
										KOMTEK Brannforebygging stenges <b>også</b> for brukere,
										kommuner og IKS som ikke er berørt av kommunenummer endring.
										Dette har å gjøre med felles datasett i vår skyløsning.
									</li>
									<li>
										Dersom arbeidet med endring av kommunenummer er gjennomført
										og verifisert tidligere, skal brukere v/ kontaktperson
										varsles umiddelbart pr epost og pr telefon.
									</li>
									<li>
										Norkarts kundeansvarlige og Norkarts Kundestøtte er
										tilgjengelig for spørsmål.
									</li>
								</ul>
								Norkart har simulert kommunenummerendring i ulike testmiljøer
								for å unngå uforutsette hendelser. Det er vår erfaring som
								tilsier at slike endringer i «skarpfase» likevel kan medføre
								uforutsette hendelser som kan påvirke gjennomføringen. Vi ber
								dere derfor være forberedt på dette. Informasjon om slike
								hendelser skal gis umiddelbart.
								<br />
								Denne informasjon sendes våre kunder pr epost, legges ut på
								Norkarts kundesenter, og kunngjøres ved innlogging i KOMTEK
								Brannforebygging pr 16. desember 2019.
								<br />
								Har du spørsmål knyttet til regionreformen? Ta kontakt med din
								kundeansvarlige eller{' '}
								<a
									href='https://komtek.zendesk.com/hc/no/sections/4986289245585-Brannforebygging'
									target='_blank'>
									Norkarts kundestøtte
								</a>
								<br />
								<br />
								Med vennlig hilsen,
								<br />
								<br />
								Norkart
								<br />
								<i>Tommy Haugen</i>
								<br />
								Markedssjef KOMTEK
								{/* ************************* */}
							</div>
							<br />
							{/* ************************* */}
							<div
								className=' collapsed'
								data-toggle='collapse'
								data-target='#04122019'>
								<span style={{ marginLeft: '10px', fontSize: '18px' }}>
									<strong style={{ color: '#617dad', cursor: 'pointer' }}>
										<u>04.12.2019 - SMS til alle eiere</u>
									</strong>
								</span>
							</div>
							<div
								style={{ marginLeft: '20px' }}
								className='collapse'
								id='04122019'>
								<h3>SMS TIL ALLE EIERE</h3>
								<ul>
									<li>
										Det er nå laget mulighet for å sende SMS til alle eiere av
										en bruksenhet ved varslinger og tilbakemeldinger etter
										feiing og tilsyn.{' '}
									</li>
									<li>
										Funksjonen må aktiveres under innstillinger for
										organisasjon.{' '}
									</li>
								</ul>
							</div>
							{/* ************************* */}
						</div>
					</div>
				</div>

				{/*    <div className='panel panel-primary'> 
          <div
            className='panel-heading collapsed'
            data-toggle='collapse'
            data-target='#oppdateringer'>
            <i className='fa fa-fw fa-chevron-down' />
            <i className='fa fa-fw fa-chevron-up' />
            <span style={{ marginLeft: '10px', fontSize: '22px' }}>
              Oppdateringer
            </span>
          </div>
          <div className='panel-body'>
            <div className='collapse' id='oppdateringer'>
             
              <div
                className=' collapsed'
                data-toggle='collapse'
                data-target='#04122019'>
                <span style={{ marginLeft: '10px', fontSize: '18px' }}>
                  <strong style={{ color: '#617dad', cursor: 'pointer' }}>
                    <u>04.12.2019 - SMS til flere eiere</u>
                  </strong>
                </span>
              </div>
              <div
                style={{ marginLeft: '20px' }}
                className='collapse in'
                id='04122019'>
                <br />
                <ul>
                  <li>
                    Det er nå laget mulighet for å sende SMS til alle eiere av
                    en bruksenhet ved varslinger og tilbakemeldinger etter
                    feiing og tilsyn.{' '}
                  </li>
                  <li>
                    Funksjonen må aktiveres under innstillinger for
                    organisasjon.{' '}
                  </li>
                </ul>
              </div>

             
            </div>
          </div>
        </div>*/}
				<br />
			</div>
		);
	}
}
