import React, { Component } from 'react';
import _ from 'underscore';
import NKIcon from 'nka-icons';
import './AvvikAnmerkninger.css';
import * as avvikApi from 'api/avvikApi';
import NKModal from 'components/Dialogs/NKModal.jsx';
import SoekDialog from 'components/Dialogs/SoekDialog.jsx';
import PrikDialog from 'components/Dialogs/PrikDialog.js';
import LukkAvvikDialog from './LukkAvvikDialog2.jsx';
import BilderPanel from 'components/Bilder/BilderPanel.jsx';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import LagreTekstbit from './LagreTekstbit.jsx';
import SoekTekstbit from './SoekTekstbit.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogHistorikkAvvik from './DialogHistorikkAvvik.jsx';
import { sendTilEierFraGjennomforingLukkPublisert2 } from 'api/innbyggerDialogApi';
import { opprettSakOgKobleTilBruksenhet } from 'api/arkivApi';
import { deleteInnbyggerDialogAvvik } from 'api/innbyggerDialogApi';
import { getPerson } from 'api/personApi';
import DialogStore from 'lib/DialogStore';
import './selectsearch.css';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';
import { debugFactory } from 'utils';
import { getBruksenhetKontakter2 } from 'api/kontaktApi.js';
const debug = debugFactory('nk:AvvikForm');

const fristDager = 21;
var endretAvvik = null;
let gjelderTekst = '';
let eierKontakter = null;
let opprinneligLukket = false;

const modalDialogs = {
  ReAapneDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Åpne lukket avvik '
      melding="Du åpner nå et lukket avvik som er publisert. Ved å åpne det igjen settes avviket til 'Ikke publisert' "
      isOpen={isOpen}
      onOk={owner.reApneOk.bind(this)}
      onClose={onClose}
    />
  ),
  SletteDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Slette?'
      melding={dialogData.melding}
      isOpen={isOpen}
      onOk={owner.slettOk.bind(this)}
      onClose={onClose}
    />
  ),
  SlettePublisertDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Slette publisert avvik?'
      melding={dialogData.melding}
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
  FristManglerDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Angi frist'
      melding='Du må angi frist for tilbakemelding på avviket.'
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
  TiltakManglerDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Angi tiltaktype'
      melding='Du må angi tiltakstype på avviket.'
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
  SaksbehandlerManglerDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Angi saksbehandler'
      melding='Du må angi saksbehandler på anmerkning.'
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
  LagreDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Kan ikke lagre'
      melding='Avvik kan IKKE lagres uten en avvikstype.'
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
  TekstbiterDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen}>
      <LagreTekstbit
        merkettekst={dialogData.merkettekst}
        modul='avvik'
        tekst={dialogData.tekst}
        onClose={owner.closeTekstbit.bind(this)}
        onLagret={owner.lagretTekstbit.bind(this)}
      />
    </NKModal>
  ),
  LukkAvvikDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <LukkAvvikDialog
      avvik={dialogData.avvik}
      bruksenheter={dialogData.bruksenheter}
      bruksenhetid={dialogData.bruksenhetid}
      adresse={dialogData.adresse}
      isOpen={isOpen}
      onClose={onClose}
      onOk={owner.lukkOk.bind(this)}
      opprettSak={owner.opprettSak}
      site='gjennomforing'
      info={dialogData.info}
    />
  ),
  TekstbiterSoekDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <SoekTekstbit
        modul='avvik'
        onClose={onClose}
        onVelgTekst={owner.onValgtTekst.bind(this)}
      />
    </NKModal>
  ),
  SoekAvvikTypeDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <SoekDialog
      tittel={dialogData.tittel}
      liste={dialogData.liste}
      ident={dialogData.ident}
      tekst={dialogData.tekst}
      checkAktiv={dialogData.checkAktiv}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
      onValgt={owner.onAvvikTypeValgt}
    />
  ),
  FeiltegnDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Tegn ikke tilatt'
      melding='Beskrivelsen kan ikke inneholde spesialtegn som \.'
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
  UnderBehandlingSidemannDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Lukke avvik '
      melding='Avvik ligger tilknyttet en dokumentkontroll under behandling. Vil du lukke avviket likevel? '
      isOpen={isOpen}
      onOk={owner.fortsettLagre.bind(this)}
      onClose={onClose}
    />
  ),
  UnderBehandlingSidemannSlettDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Slett avvik '
      melding='Avvik ligger tilknyttet en dokumentkontroll under behandling. Vil du slette avviket likevel? '
      isOpen={isOpen}
      onOk={owner.fortsettSlett.bind(this)}
      onClose={onClose}
    />
  ),
  UnderBehandlingIkkeSidemannDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Lukke avvik '
      melding='Avvik kan ikke slettes/lukkes fordi det er tilknyttet en rapport som ligger til klargjøring etter tiltak. Du må fjerne oppgaven for denne rapporten, før du kan slette/lukke avviket. '
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
};

class ListeParagrafer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
    };
    this.checkClick = this.checkClick.bind(this);
    this.paragrafClick = this.paragrafClick.bind(this);
    this.getChecked = this.getChecked.bind(this);
  }

  getChecked() {
    let paragrafListe = [];
    let boxes = document.getElementsByClassName('checkbox20');
    if (boxes !== undefined) {
      for (let index = 0; index < boxes.length; index++) {
        let box = boxes[index];
        let para = [];
        para.paragrafId = box.alt;
        para.checked = box.checked;
        paragrafListe.push(para);
      }
    }
    return paragrafListe;
  }

  checkClick(e) {
    if (e.target.value === 'off') {
      e.target.value = 'on';
    } else {
      e.target.value = 'off';
    }
  }

  paragrafClick = (e) => {
    let r = 'i' + e.target.id;
    document.getElementById(r).checked = !document.getElementById(r).checked;
  };

  slettInaktivParagraf = (e, par, y) => {
    let p = 'i' + par.paragrafId;
    document.getElementById(p).checked = false;
  };


  render() {
    let paragrafListe = _.sortBy(
      this.props.aktuelleparagrafer,
      'aktiv'
    ).reverse();

    return (
      <div>
        <div>
          <table>
            <tbody>
              {paragrafListe.map(function(p) {
                var trId = 'tr' + p.paragrafId;
                var inId = 'i' + p.paragrafId;
                var divId = 'd' + p.paragrafId;
                var tr1Id = 't1' + p.paragrafId;
                var tr2Id = 't2' + p.paragrafId;
                var tr3Id = 't3' + p.paragrafId;
                var tr4Id = 't4' + p.paragrafId;
                var isChecked = false;
                let found = [];
                let soppel = <td />;
                found = this.props.valgteparagrafer.filter(
                  (item) =>
                    item.paragrafId.toString() === p.paragrafId.toString()
                );
                if (found.length > 0) {
                  isChecked = true;
                  /*      soppel = (
                    <td>
                      <div>
                        <NKIcon
                          icon='soppelbotte'
                          size='0.8'
                          cursor='pointer'
                          color='nk-black'
                          onClick={this.slettInaktivParagraf.bind( this,  trId,  p )}
                        />
                        <span style={{ marginLeft: '8px', color: 'darkRed' }}>
                          Fjern!
                        </span>
                      </div>
                    </td>
                  ); */
                }
                return (
                  <tr key={trId}>
                    <td key={tr1Id}>&nbsp;&nbsp;</td>
                    <td key={tr2Id} className='tdTop'>
                      <div key={divId} className='tdTop'>
                        <input
                          alt={p.paragrafId}
                          className='checkbox20'
                          ref={p.paragrafId}
                          id={inId}
                          key={inId}
                          defaultChecked={isChecked}
                          onClick={this.checkClick}
                          type='checkbox'
                          /* disabled={!p.aktiv} */
                        />
                      </div>
                    </td>
                    <td key={tr3Id}>&nbsp;&nbsp;</td>
                    {p.aktiv && (
                      <td
                        id={p.paragrafId}
                        key={tr4Id}
                        onClick={this.paragrafClick}>
                        {p.paragraf}
                      </td>
                    )}
                    {!p.aktiv && (
                      <>
                        <td
                          id={p.paragrafId + 'not'}
                          key={tr4Id}
                          onClick={this.paragrafClick}>
                          <span style={{ color: 'gray', fontStyle: 'italic' }}>
                            {p.paragraf}
                            <span
                              style={{
                                marginLeft: '6px',
                                color: 'blue',
                                fontStyle: 'italic',
                              }}>
                              Ikke aktiv
                            </span>
                          </span>
                        </td>
                        {soppel}
                      </>
                    )}
                  </tr>
                );
              }, this)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

class AvvikForm extends React.Component {
  constructor(props) {
    super(props);
    this.areaRef = React.createRef();
    this.state = {
      tittel: 'Nytt avvik',
      avvik: null,
      lukket: false,
      gjelderBygg: false,
      visgjelderbygning: '',
      lukketText: 'Åpen',
      dato: moment(),
      frist: moment().add('days', fristDager),
      saksbehandlere: [],
      saksbehandlereAktiv: [],
      feilSaksbehandler: '',
      saksbehandler: '',
      avviktyper: [],
      avvikTypeOptions: [],
      paragrafer: [],
      tiltakstyper: [],
      aktuelleparagrafer: [],
      valgteparagrafer: [],
      statuser: [],
      filer: [],
      tekstbit: '',
      tekst: '',
      merkettekst: '',
      bilderCallbacks: null,
      antallBilder: 0,
      sendTilEier: false,
      unntatt: false,
      tilgang: null,
      hjemmel: null,
      fristDager: '21',
      lukketArsakListe: [],
      lukketArsak: null,
      sakOpprettet: false,
      laster: false
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
    this.getNewAvvik = this.getNewAvvik.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.hentData = this.hentData.bind(this);
    this.bilderMounted = this.bilderMounted.bind(this);
    this.slettOk = this.slettOk.bind(this);
    this.saksbehandlerChange = this.saksbehandlerChange.bind(this);
    this.getPaaloggetBruker = this.getPaaloggetBruker.bind(this);
    this.paragraferChanged = this.paragraferChanged.bind(this);
    this.gjelderByggChange = this.gjelderByggChange.bind(this);
    this.lukketChange = this.lukketChange.bind(this);
    this.makeLagreListenParagrafer = this.makeLagreListenParagrafer.bind(this);
    this.finnesFraFor = this.finnesFraFor.bind(this);
    this.beregnAntallBilder = this.beregnAntallBilder.bind(this);
    this.onValgtTekst = this.onValgtTekst.bind(this);
    this.lagretTekstbit = this.lagretTekstbit.bind(this);
    this.closeTekstbit = this.closeTekstbit.bind(this);
    this.lukkOk = this.lukkOk.bind(this);
    this.avvikLagred = this.avvikLagred.bind(this);
    //this.avvikLagredOgLukket = this.avvikLagredOgLukket.bind(this);
    this.sendTilEierDone = this.sendTilEierDone.bind(this);
  }

  componentDidMount() {
    this.setState({ tittel: this.state.tittel + ' - ' + this.props.adresse });
    this.hentData();
    //skal gjelderBygg vises?
    if (this.props.objektReferanseType !== 'B') {
      this.setState({ visgjelderbygning: 'hide' });
    }
  }

  //  **** GET DATA **********************************************************************
  hentData() {
    debug('PUK', this.props);
    avvikApi.getAvvikOppslagdata(
      this.props.objektReferanseType,
      this.props.objektReferanseId,
      this.gotOppslagdata.bind(this)
    );
  }

  getAktiveSaksbehandlere = (saksbehandlere) => {
    let s = [
      { id: null, navn: '', brukernavn: null, aktiv: false, paaLogget: false },
    ];
    for (let i = 0; i < saksbehandlere.length; i++) {
      if (saksbehandlere[i].aktiv) {
        s.push(saksbehandlere[i]);
      }
    }
    return s;
  };

  gotOppslagdata(err, data) {
    gjelderTekst = data.objektPlassering;
    let statusListe = data.statusListe;
    let arsakliste = data.lukketArsakListe;
    let obj = { id: null, tekst: '', aktiv: false };
    statusListe.unshift(obj);
    arsakliste.unshift(obj);
    this.makeAvviktypeOptions(data.avvikTypeListe);
    this.setState({
      saksbehandlere: data.saksbehandler,
      saksbehandlereAktiv: this.getAktiveSaksbehandlere(data.saksbehandler),
      avviktyper: this.utvidAvviktyper(data.avvikTypeListe),
      paragrafer: data.paragrafListe,
      tiltakstyper: this.utvidTiltak(data.tiltakTypeListe),
      aktuelleparagrafer: [],
      statuser: statusListe,
      lukketArsakListe: arsakliste,
    });
    this.getAvvik();
  }

  utvidAvviktyper(liste) {
    let typ = { id: '', tekst: '', tekst2: '', fremmedNokkel: '' };
    liste.unshift(typ);
    return liste;
  }

  utvidTiltak(liste) {
    let til = { id: null, tekst: null, tekst2: null, fremmedNokkel: null };
    liste.unshift(til);
    return liste;
  }

  getAvvik() {
    if (this.props.id === '0') {
      this.getNewAvvik();
    } else {
      avvikApi.getAvvikById(this.props.id, this.gotAvvik.bind(this));
    }
  }

  gotAvvik(err, data) {
    endretAvvik = data;
    opprinneligLukket = data.lukket;
    this.setState({ avvik: data });

    let a = _.find(this.state.saksbehandlere, {
      id: data.saksbehandlerId,
    });

    let saksbeh = this.state.saksbehandlere.find(
      (y) => y.id === data.saksbehandlerId
    );

    if (saksbeh) {
      this.setState({ saksbehandlertekst: saksbeh.navn });
    } else {
      this.setState({ saksbehandlertekst: 'Ukjent' });
    }

    let avvikType = this.state.avviktyper.find(
      (x) => x.id === data.avvikTypeId
    );
    this.setState({
      avvikTypeTekst: avvikType !== undefined ? avvikType.tekst : null,
    });

    let tiltakType = this.state.tiltakstyper.find(
      (x) => x.id === data.tiltakTypeId
    );
    this.setState({
      tiltaktypeTekst: tiltakType !== undefined ? tiltakType.tekst : null,
    });

    if (a === undefined) {
      getPerson(data.saksbehandlerId, this.gotPersoner);
    }
    //Ifm exception
    if (!data.hasOwnProperty('reApnet')) {
      data.reApnet = false;
    }

    this.setState({ valgteparagrafer: data.paragrafRegistrert });

    //skal gjelderBygg vises?
    if (data.objektReferanseType === 'B') {
      this.setState({ visgjelderbygning: '' });
    } else {
      this.setState({ visgjelderbygning: 'hide' });
    }

    this.setLukket(data.lukket);
    this.setState({
      avvik: data,
      tittel: 'Endre avvik - ' + this.props.adresse,
      gjelderBygg: data.gjelderBygg,
      lukket: data.lukket,
      filer: data.filer,
      dato: data.dato !== null ? moment(data.dato) : null,
      frist: data.frist !== null ? moment(data.frist) : null,
    });
    this.tilpassAktuelleParagrafer(data.avvikTypeId);
    this.beregnAntallBilder(data.filer);
    this.sjekkSaksbehandler(data.saksbehandlerId);
  }

  sjekkOmParagraferFinnes = () => {};

  sjekkSaksbehandler = (id) => {
    let liste = this.state.saksbehandlere;
    let sb = null;
    let feilSaksbehandler = '';
    for (let i = 0; i < liste.length; i++) {
      let s = liste[i];
      if (s.id === id) {
        sb = liste[i];
      }
    }
    if (sb) {
      if (!sb.aktiv) {
        feilSaksbehandler =
          'Opprinnelig saksbehandler ' +
          sb.navn +
          ' er non-aktiv, velg en ny saksbehandler.';
        endretAvvik.saksbehandlerId = null;
      }
    } else {
      feilSaksbehandler =
        'Opprinnelig saksbehandler er ukjent, velg en ny saksbehandler.';
      endretAvvik.saksbehandlerId = null;
    }

    this.setState({ feilSaksbehandler });
  };

  gotPersoner = (res, data) => {
    console.log(data);
    if (data !== null && data !== undefined) {
      var tmp = this.state.saksbehandlere;
      tmp.unshift(data);
      this.setState({ saksbehandlere: tmp });
    }
    // let pers = _.find(this.state.saksbehandlere, {
    //   id: data.saksbehandlerId
    // });
  };

  tilpassAktuelleParagrafer(avvikTypeId) {
    if (isNaN(avvikTypeId) || avvikTypeId === '' || avvikTypeId === null) {
      this.setState({ aktuelleparagrafer: [], valgteparagrafer: [] });
    } else {
      let paras = this.state.paragrafer;
      let aktuelle = _.filter(paras, function(para) {
        return para.avvikTypeId === avvikTypeId;
      });
      this.setState({ aktuelleparagrafer: aktuelle });
    }
  }

  getNewAvvik() {
    let nyAvvik = {
      id: '0',
      gjelderBygg: false,
      avvikTypeId: '',
      dato: moment().format('YYYY-MM-DDTHH:00:00'),
      frist: moment()
        .add('days', this.state.fristDager)
        .format('YYYY-MM-DD'),
      beskrivelse: '',
      bruksenhetId: this.props.bruksenhetid,
      lukket: false,
      lukketArsakId: null,
      objektReferanseId: this.props.objektReferanseId,
      objektReferanseType: this.props.objektReferanseType,
      paragrafRegistrert: [],
      filer: [],
      statusId: null,
      saksbehandlerId: '',
      publisert: false,
      tiltakTypeId: this.getFirstTiltak(),
      reApnet: false,
    };
    //nyAvvik.avvikTypeId = this.checkOmAvvikTypeFinnes('0');
    nyAvvik.saksbehandlerId = this.getPaaloggetBruker();
    // nyAvvik.statusId= this.checkOmStatusFinnes('0');

    this.tilpassAktuelleParagrafer(nyAvvik.avvikTypeId);
    endretAvvik = nyAvvik;

    this.setLukket(false);
    this.setState({ avvik: nyAvvik });
    this.setState({ antallBilder: 0 });
  }

  getFirstTiltak() {
    let first = _.first(this.state.tiltakstyper);
    return first ? first.id : null;
  }

  getPaaloggetBruker() {
    let saksbehandlere = this.state.saksbehandlere;
    let result = '';
    for (let i = 0; i < saksbehandlere.length; i++) {
      if (saksbehandlere[i].paaLogget) {
        result = saksbehandlere[i].id;
        break;
      }
    }
    if (!result) {
      result = 0;
      //result = _.first(this.state.saksbehandlere).id;
    }
    return result;
  }

  setLukket(lukket) {
    if (lukket) {
      this.setState({
        lukket: true,
        lukketText: 'Lukket',
      });
    } else {
      this.setState({
        lukket: false,
        lukketText: 'Åpen',
      });
    }
    endretAvvik.lukket = lukket;
  }

  //  **** **** **********************************************************************

  // **** Rediger ***********************************************************************
  lukketChange() {
    let lukket = !this.state.lukket;
    this.setState({ lukket: lukket });
    endretAvvik.lukket = lukket;
  }

  gjelderByggChange() {
    let bygg = !this.state.gjelderBygg;
    this.setState({ gjelderBygg: bygg });
    endretAvvik.gjelderBygg = bygg;
  }

  datoChange(val) {
    this.setState({ dato: val });
    endretAvvik.dato = moment(val).format('YYYY-MM-DDTHH:00:00');
  }

  fristChange(val) {
    this.setState({ frist: val });
    endretAvvik.frist = moment(val).format('YYYY-MM-DDTHH:00:00');
  }

  saksbehandlerChange(val) {
    endretAvvik.saksbehandlerId = val.target.value;
    let avvik = this.state.avvik;
    avvik.saksbehandlerId = val.target.value;
    this.setState({ avvik: avvik });
  }

  paragraferChanged(liste) {
    this.setState({ valgteparagrafer: liste });
    endretAvvik.paragrafRegistrert = liste;
  }

  statusChange(val) {
    let x = parseInt(val.target.value);
    endretAvvik.statusId = x;
  }

  beskrivelseChange(val) {
    if (val.target.value.includes('\\')) {
      this.dialogs.setOpenDialog('FeiltegnDialog');
    } else {
      endretAvvik.beskrivelse = val.target.value;
    }
  }

  arsakChange = (val) => {
    endretAvvik.lukketArsakId = val.target.value;
  };

  //  **** Bilder **********************************************************************
  bilderMounted(callbacks) {
    this.setState({ bilderCallbacks: callbacks });
  }

  startHentBilder() {
    //Bildene kan kun knyttes til en eksisterende anavvik..
    if (endretAvvik.id === 0) {
      avvikApi.createAvvik(endretAvvik, this.avvikForBilderLagred.bind(this));
    } else {
      this.state.bilderCallbacks.start(
        'hentbilder for avvik ' + endretAvvik.id
      );
    }
  }

  avvikForBilderLagred(err, obj) {
    endretAvvik = obj;
    this.state.bilderCallbacks.start('hentbilder for avvik ' + obj.id);
  }

  onfillListeChange(data) {
    let bilde = {
      bruksenhetId: this.props.bruksenhetid,
      id: '0',
      filnavn: data,
      filtittel: 'Avvik',
      ny: true,
      objektReferanseId: this.props.objektReferanseId,
      objektReferanseType: this.props.objektReferanseType,
      slett: false,
    };

    let filliste = this.state.filer;

    filliste.push(bilde);
    this.setState({ filer: filliste });
    this.beregnAntallBilder(filliste);
  }

  beregnAntallBilder(filer) {
    let antall = 0;
    for (let index = 0; index < filer.length; index++) {
      let bilde = filer[index];
      if (!bilde.slett) {
        antall++;
      }
    }
    this.setState({ antallBilder: antall });
  }

  onBildeSlettet(data) {
    let filer = this.state.filer;
    for (let i = 0; i < filer.length; i++) {
      let bilde = filer[i];
      if (bilde.filnavn === data) {
        bilde.slett = true;
      }
    }
    this.setState({ filer: filer });
    this.beregnAntallBilder(filer);
  }

  //  ****  Tekster ***********************************************************************
  areaSelected() {
    var ta = this.areaRef.current;
    var ss = ta.selectionStart;
    var se = ta.selectionEnd;
    var s = ta.value.substring(ss, se);
    this.setState({ merkettekst: s });
  }

  openTekstbit() {
    this.setState({ tekst: this.areaRef.current.value });
    this.dialogs.setOpenDialog('TekstbiterDialog', {
      tekst: this.areaRef.current.value,
      merkettekst: this.state.merkettekst,
    });
  }

  closeTekstbit() {
    console.log('************ close 2 ************************');
    this.dialogs.closeDialog();
    this.setState({ merkettekst: '' });
  }

  openTekstbitSoek() {
    this.dialogs.setOpenDialog('TekstbiterSoekDialog');
  }

  lagretTekstbit() {
    this.dialogs.closeDialog();
    //Update tekster
    this.setState({ merkettekst: '' });
  }

  onValgtTekst(tekst) {
    var ta = this.areaRef.current;
    ta.focus();
    var ss = ta.selectionStart;
    var se = ta.selectionEnd;
    tekst = tekst + ' ';
    ta.value =
      ta.value.substring(0, ss) +
      tekst +
      ta.value.substring(se, ta.value.length);
    ta.setSelectionRange(ss + tekst.length + 2, ss + tekst.length + 2);

    let a = this.state.avvik;
    a.beskrivelse = ta.value;
    this.setState({ avvik: a });

    this.dialogs.closeDialog();
  }

  paragrafClick(val, e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    let url = val.paragrafUrl;
    var redirectWindow = window.open(url, '_blank');
    redirectWindow.location();
  }

  finnesFraFor(paragrafId) {
    let result = false;
    let orginalListe = endretAvvik.paragrafRegistrert;
    for (let index = 0; index < orginalListe.length; index++) {
      let pp = orginalListe[index];
      if (pp.paragrafId.toString() === paragrafId.toString()) {
        result = true;
        break;
      }
    }
    return result;
  }

  makeLagreListenParagrafer() {
    let lagreListe = [];
    let fraComponent = this.listeParagrafer.getChecked();
    for (let index = 0; index < fraComponent.length; index++) {
      let p = fraComponent[index];
      let nyP = {};
      nyP.paragrafId = Number(p.paragrafId);
      if (p.checked) {
        if (this.finnesFraFor(p.paragrafId)) {
          nyP.slett = false;
          nyP.ny = false;
          nyP.avvikId = Number(endretAvvik.id);
          lagreListe.push(nyP);
        } else {
          nyP.slett = false;
          nyP.ny = true;
          nyP.avvikId = Number(endretAvvik.id);
          lagreListe.push(nyP);
        }
      } else {
        if (this.finnesFraFor(p.paragrafId)) {
          nyP.slett = true;
          nyP.ny = false;
          nyP.avvikId = Number(endretAvvik.id);
          lagreListe.push(nyP);
        }
      }
    }

    return lagreListe;
  }

  avvikTypeValgt() {
    if (
      isNaN(endretAvvik.avvikTypeId) ||
      endretAvvik.avvikTypeId === '' ||
      endretAvvik.avvikTypeId === null
    ) {
      this.dialogs.setOpenDialog('LagreDialog');
      return false;
    } else {
      return true;
    }
  }

  lagre = () => {
    console.log('lagre');
    if (endretAvvik.lukket && !opprinneligLukket) {
      if (endretAvvik.oppfolgingType === null) {
        this.fortsettLagre();
      } else {
        if (endretAvvik.sidemannId !== null && endretAvvik.sidemannId !== 0) {
          // Klargjør rapport, under sidemann, ikke tilatt å lukke/slette
          this.dialogs.setOpenDialog('UnderBehandlingSidemannDialog');
        } else {
          // Inder behandling, ikke sidemann, lov å lukke/slette
          this.dialogs.setOpenDialog('UnderBehandlingIkkeSidemannDialog');
        }
      }
    } else {
      this.fortsettLagre();
    }
  }

  fortsettLagre = () => {
    let ed = moment(this.state.frist)
      .add(1, 'days')
      .toDate();

    if (!endretAvvik.tiltakTypeId) {
      this.dialogs.setOpenDialog('TiltakManglerDialog');
      return;
    }
    if (!endretAvvik.saksbehandlerId) {
      this.dialogs.setOpenDialog('SaksbehandlerManglerDialog');
      return;
    }

    // Hændterer feil ved frist hvis endre
    endretAvvik.frist = this.props.id === '0' ? this.state.frist : ed;

    if (endretAvvik.frist === 'Invalid date') {
      this.dialogs.setOpenDialog('FristManglerDialog');
      return;
    }

    if (this.avvikTypeValgt()) {
      let bilder = [];
      endretAvvik.paragrafRegistrert = this.makeLagreListenParagrafer();
      for (let i = 0; i < this.state.filer.length; i++) {
        let b = this.state.filer[i];
        if (b.ny === true && b.slett === true) {
          //Ingenting
        } else {
          bilder.push(b);
        }
      }
      endretAvvik.filer = bilder;

      if (endretAvvik.id === '0') {
        console.log(endretAvvik);
        avvikApi.createAvvik(endretAvvik, this.avvikLagred.bind(this));
      } else if (
        endretAvvik.lukket &&
        endretAvvik.publisert &&
        !opprinneligLukket
      ) {
        var bruksenheter = this.props.bruksenhetid.toString();
        this.dialogs.setOpenDialog('LukkAvvikDialog', {
          bruksenheter: bruksenheter,
          bruksenhetid: this.props.bruksenhetid,
          avvik: this.state.avvik,
          adresse: this.props.adresse,
          info: this.state.sakOpprettet
        });
      } else if (
        !endretAvvik.lukket &&
        opprinneligLukket &&
        endretAvvik.publisert
      ) {
        this.dialogs.setOpenDialog('ReAapneDialog');
      } else {
        avvikApi.updateAvvik(
          endretAvvik.id,
          endretAvvik,
          this.avvikLagred.bind(this)
        );
      }
    }
  }
  reApneOk = () => {
    endretAvvik.reApnet = true;
    endretAvvik.publisert = false;
    avvikApi.updateAvvik(
      endretAvvik.id,
      endretAvvik,
      this.avvikLagred.bind(this)
    );
  };

  lukkOk(merknad, eierkontakter, unntatt, tilgang, hjemmel) {
    this.setState({ sendTilEier: true });
    this.setState({
      merknad: merknad,
      unntatt: unntatt,
      tilgang: tilgang,
      hjemmel: hjemmel,
    });
    eierKontakter = eierkontakter;
    this.dialogs.closeDialog();
    let lukket = !this.state.lukket;
    this.setState({ lukket: lukket });
    endretAvvik.lukket = true;
    avvikApi.updateAvvik(
      endretAvvik.id,
      endretAvvik,
      this.avvikLagredOgLukket.bind(this)
    );
    //this.avvikLagredOgLukket();
  }

  slett() {
      if (endretAvvik.oppfolgingType === null) {
        this.fortsettSlett();
      } else {
        if (endretAvvik.sidemannId !== null && endretAvvik.sidemannId !== 0) {
          // Klargjør rapport, under sidemann, lov å slette
          this.dialogs.setOpenDialog('UnderBehandlingSidemannSlettDialog');
        } else {
          // Inder behandling, ikke sidemann, 
          this.dialogs.setOpenDialog('UnderBehandlingIkkeSidemannDialog');
        }
      }
  }

  fortsettSlett = () => {
    if (endretAvvik.publisert === true) {
      let melding =
        'Du kan ikke slette et avvik som allerede har blitt publisert. Gå evt. til oppfølging for å endre avviket.';
      this.dialogs.setOpenDialog('SlettePublisertDialog', { melding: melding });
    } else {
      let melding = 'Du er i ferd med å slette et avvik. Fortsette?';
      this.dialogs.setOpenDialog('SletteDialog', { melding: melding });
    }
  }

  slettOk() {
    this.dialogs.closeDialog();
    if (endretAvvik.id === '0') {
      this.props.onClose();
    } else {
      avvikApi.deleteAvvik(endretAvvik.id, this.avvikLagred.bind(this));
    }
  }

  checkOmAvvikTypeFinnes(id) {
    let exists = false;
    for (var i = 0; i < this.state.avviktyper.length; i++) {
      if (this.state.avviktyper[i].id === id) {
        exists = true;
        break;
      }
    }
    if (!exists) {
      id = this.state.avviktyper[0].id;
    }
    return id;
  }

  checkOmStatusFinnes(id) {
    let exists = false;
    for (var i = 0; i < this.state.statuser.length; i++) {
      if (this.state.statuser[i].id === id) {
        exists = true;
        break;
      }
    }
    if (!exists) {
      id = this.state.statuser[0].id;
    }
    return id;
  }

  avvikLagred(err, obj) {
    this.props.onLagred();
    this.props.onClose();
  }
  avvikLagredOgLukket = () => {
    eierKontakter.forEach((e) => {
      if (e.telefon == null) {
        e.smsUtelat = true;
      }
    });
    let skjerming = null;
    if (this.state.unntatt) {
      skjerming = {
        Skjermingshjemmel: this.state.hjemmel,
        Tilgangsrestriksjon: this.state.tilgang,
      };
    }
    let param = {
      dialogTekst: this.state.merknad,
      avvikId: endretAvvik.id,
      avvikTypeTekst: this.props.avvikTekst,
      tiltakTypeId: this.state.avvik.tiltakTypeId,
      bruksenhetKontakt: eierKontakter,
      skjerming: skjerming,
    };
    console.log(param);
    sendTilEierFraGjennomforingLukkPublisert2(param, this.sendTilEierDone);
    //this.props.onLagred();
  };

  sendTilEierDone(err, data) {
    console.log(err);
    if (err) {
      this.lagret();
    } else {
      this.setState({ sendTilEier: false });
      this.lagret();
      // this.props.onClose();
      // this.props.steng();
      // let lukket = !this.state.lukket;
      // this.setState({ lukket: lukket });
      // endretAvvik.lukket = true;
      // avvikApi.updateAvvik(
      //   endretAvvik.id,
      //   endretAvvik,
      //   this.lagret
      // );
    }

    // this.props.onClose();
    // this.props.steng();
  }
  lagret = () => {
    this.props.onClose();
    this.props.steng();
    this.props.onLagred();
  };

  slettAvvikDialog = () => {
    deleteInnbyggerDialogAvvik(this.props.id, (err, res) => {
      console.log('Avvik dialog slettet');
    });
  };

  makeAvviktypeOptions = (liste) => {
    let avvikTypeOptions = [];
    for (let i = 0; i < liste.length; i++) {
      const element =liste[i];
      let obj = { value: element.id, name: element.tekst };
      avvikTypeOptions.push(obj);
    }
    debug('GRAX options', avvikTypeOptions);
     this.setState({ avvikTypeOptions });
  };

  openAvvikTyper = () => {
    this.dialogs.setOpenDialog('SoekAvvikTypeDialog',  {
      tittel: 'Søk avviktype',
      liste: this.state.avviktyper,
      ident: 'id',
      tekst: 'tekst',
      checkAktiv: 'aktiv'
    });
  };

  avviktypeChange(id) {
    this.setState({ valgteparagrafer: [] });
    endretAvvik.avvikTypeId = id;
    let av = _.find(this.state.avviktyper, {
      id: id,
    });

    this.setState({
      fristDager: av.fristDefault,
      frist: moment().add('days', av.fristDefault),
    });
    this.tilpassAktuelleParagrafer(id);
  }

  onAvvikTypeValgt = (type) => {
    debug('Lotta', type);
    this.dialogs.closeDialog();
    this.setState({ avvikTypeTekst: type.tekst });
    this.avviktypeChange(type.id);
  };

  opprettSak = () => {
    this.setState({ laster: true });
    let bruksenhetids = [];
    bruksenhetids.push(this.props.bruksenhetid);
    getBruksenhetKontakter2(bruksenhetids, this.gotEierkontakter);

    
  }

  gotEierkontakter = (err, res) => {
    console.log(res);
    let br = res[0];
    opprettSakOgKobleTilBruksenhet(this.props.bruksenhetid, br.bruksenhetAdresse, br.matrikkelenhetId, this.sakOpprettet);
    this.dialogs.closeDialog();
  }

  sakOpprettet = (err, res) => {
    console.log(res);
    this.setState({ sakOpprettet: true, laster: false });
    this.lagre();
  }

  render() {
    let accStyle = {
      width: '100%',
      background: '#f6f6f6',
      borderColor: '#ccc',
      maxHeight: '42px',
      textAlign: 'left',
      padding: '0 10px',
      borderRight: '0',
      borderLeft: '0',
    };

    let chevronStyle = {
      float: 'right',
      marginTop: '12px',
      marginRight: '4px',
    };

    let noZoom = {
      fontSize: '16px',
      transform: 'scale(0.95)',
      transformOrigin: 'top left',
    };

    if (
      !this.state.avvik ||
      !this.state.paragrafer ||
      !this.state.statuser ||
      !this.state.avviktyper ||
      !this.state.avvikTypeOptions ||
      this.state.sendTilEier ||
      this.state.laster
    ) {
      return <div className='loader' />;
    }

   

    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> {this.state.tittel + '1A'} </h4>
        </div>
        <div
          className='modal-body'
          style={{
            padding: '0 0 80px',
            overflow: 'scroll',
            height: 'calc(100% - 80px)',
            maxHeight: '80vh',
            minHeight: '60vh',
          }}>
          {this.props.objektReferanseType === 'B' ? null : (
            <div style={{ margin: '10px 10px 0' }}>
              <b>{gjelderTekst}</b>
              <hr />
            </div>
          )}

          <div className='tab-content'>
            <div
              style={accStyle}
              className='container-fluid btn btn-default sbpilpanel'
              href='#avvik'
              data-toggle='collapse'>
              <h4 style={{ display: 'inline-block' }}>
                {endretAvvik.publisert === true ? 'Avvik (Publisert)' : 'Avvik'}{' '}
              </h4>
              <span style={chevronStyle} className='chevron fa fa-fw' />
            </div>

            <div
              id='avvik'
              style={{ padding: '0 15px' }}
              className='collapse in'>
              <div className='row'>
                <div className='col-xs-12'>
                  {/* Tab Rediger */}
                  <div className='tab-pane active idiv' id='1avvik'>
                    <div
                      className='row idiv margintop8'
                      style={{ display: 'inline-block', marginRight: '30px' }}>
                      <div
                        className='form-group'
                        style={{ marginBottom: '4px' }}>
                        <label>
                          <input
                            className='checkbox30'
                            type='checkbox'
                            onChange={this.lukketChange}
                            onClick={this.lukketChange}
                            checked={this.state.lukket}
                          />
                          <span className='checkboxtext18'>Lukket</span>
                        </label>
                      </div>
                    </div>
                    <div
                      className='row idiv'
                      style={{ display: 'inline-block' }}>
                      <div className='form-group'>
                        <label>
                          {endretAvvik.reApnet || endretAvvik.publisert ? (
                            <input
                              className={
                                'checkbox30 ' + this.state.visgjelderbygning
                              }
                              type='checkbox'
                              onChange={this.gjelderByggChange}
                              onClick={this.gjelderByggChange}
                              checked={this.state.gjelderBygg}
                              disabled
                            />
                          ) : (
                            <input
                              className={
                                'checkbox30 ' + this.state.visgjelderbygning
                              }
                              type='checkbox'
                              onChange={this.gjelderByggChange}
                              onClick={this.gjelderByggChange}
                              checked={this.state.gjelderBygg}
                            />
                          )}

                          <span
                            className={
                              'checkboxtext18 ' + this.state.visgjelderbygning
                            }>
                            Gjelder bygning
                          </span>
                        </label>
                      </div>
                    </div>

                    {/* Dato og Frist */}
                    <div className='row idiv'>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ paddingRight: '20px' }}>
                              <div className='form-group '>
                                <label>Dato funnet</label>
                                <br />
                                {endretAvvik.publisert === true ? (
                                  <div
                                    className='width120Heigth32'
                                    style={{ paddingTop: '5px' }}>
                                    {moment(this.state.dato).format(
                                      'DD.MM.YYYY'
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    <DatePicker
                                      className='width120Heigth32'
                                      id='anmdato'
                                      selected={this.state.dato}
                                      startDate={moment()}
                                      placeholderText='Velg dato..'
                                      todayButton={'I dag'}
                                      onChange={this.datoChange.bind(this)}
                                      isClearable={false}
                                      showYearDropdown
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className='form-group '>
                                <label>Frist tilbakemelding</label>
                                <br />
                                <DatePicker
                                  className='width120Heigth32'
                                  id='avvfrist'
                                  selected={this.state.frist}
                                  startDate={moment()}
                                  placeholderText='Velg dato..'
                                  todayButton={'I dag'}
                                  onChange={this.fristChange.bind(this)}
                                  isClearable={false}
                                  showYearDropdown
                                  setMinDate={this.state.dato}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/* Saksbehandler */}
                    <div className='row idiv'>
                      <div className='form-group '>
                        {(endretAvvik.publisert === true ||
                          this.state.feilSaksbehandler === '') && (
                          <label>Saksbehandler</label>
                        )}
                        {endretAvvik.publisert === false &&
                          this.state.feilSaksbehandler !== '' && (
                            <label style={{ color: 'red' }}>
                              {this.state.feilSaksbehandler}
                            </label>
                          )}
                        {endretAvvik.publisert === true ? (
                          <div>{this.state.saksbehandlertekst}</div>
                        ) : (
                          <div>
                            <select
                              style={noZoom}
                              className='form-control'
                              id='type'
                              onChange={this.saksbehandlerChange}
                              defaultValue={this.state.avvik.saksbehandlerId}>
                              {_.map(this.state.saksbehandlereAktiv, function(
                                o,
                                i
                              ) {
                                return (
                                  <option key={i} value={o.id}>
                                    {o.navn}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Tiltakstype */}
                    <div className='row idiv'>
                      <div className='form-group '>
                        <label>Tiltakstype</label>
                        {endretAvvik.publisert === true ? (
                          <div>{this.state.tiltaktypeTekst}</div>
                        ) : (
                          <div>
                            <select
                              style={noZoom}
                              className='form-control'
                              id='type'
                              onChange={(e) =>
                                (endretAvvik.tiltakTypeId = parseInt(
                                  e.target.value
                                ))
                              }
                              defaultValue={this.state.avvik.tiltakTypeId}>
                              {_.map(this.state.tiltakstyper, function(o, i) {
                                return (
                                  <option key={i} value={o.id}>
                                    {o.tekst}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                    </div> 

                    {/* AvvikType */}
                   {/*  <div className='row idiv'>
                      <div className='form-group '>
                        <label>Avvikstype</label>
                        {endretAvvik.publisert === true ? (
                          <div>{this.state.avvikTypeTekst}</div>
                        ) : (
                          <div>
                            <select
                              style={noZoom}
                              className='form-control'
                              id='type'
                              onChange={this.avviktypeChange.bind(this)}
                              defaultValue={this.state.avvik.avvikTypeId}>
                              {_.map(this.state.avviktyper, function(o, i) {
                                return (
                                  <option key={i} value={o.id}>
                                    {o.tekst}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                    </div>  */}

                    {/* AvvikType 2*/}
                    <div className='row idiv'>
                      <div className='form-group '>
                        <label>Avvikstype</label>
                        {endretAvvik.publisert === true ? (
                          <div>{this.state.avvikTypeTekst}</div>
                        ) : (
                          <div >
                             <PrikDialog width='490px' openSoekDialog={ this.openAvvikTyper} tekst={this.state.avvikTypeTekst}  value={this.state.avvik.avvikTypeId}></PrikDialog>
                             <br/><br/>
                          </div>
                        )}
                      </div>
                    </div>
                   

                    {/* Paragrafer  */}
                    <div className='row idiv'>
                      <div className='form-group '>
                        <label>Paragrafer</label>
                        <ListeParagrafer
                          ref={(ref) => (this.listeParagrafer = ref)}
                          valgteparagrafer={this.state.valgteparagrafer}
                          aktuelleparagrafer={this.state.aktuelleparagrafer}
                        />
                      </div>
                    </div>

                    {/* Årsak lukket */}
                    {this.state.lukket ? (
                      <div>
                        <div className='row idiv'>
                          <div className='form-group '>
                            <label>Årsak lukket</label>
                            <select
                              style={noZoom}
                              className='form-control'
                              id='type'
                              onChange={this.arsakChange.bind(this)}
                              defaultValue={this.state.avvik.lukketArsakId}>
                              {_.map(this.state.lukketArsakListe, function(
                                o,
                                i
                              ) {
                                return (
                                  <option key={i} value={o.id}>
                                    {o.tekst}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {/* Beskrivelse */}
                    <div className='row idiv'>
                      <div className='form-group'>
                        <label className='margtop20'>Beskrivelse</label>
                        <div
                          className='col-xs-6 col-sm-6 col-md-6 col-lg-6 btn-group pull-right nopadding-right'
                          role='group'>
                          <button
                            id='deleBtn'
                            ref='deleBtn'
                            type='button'
                            className='btn btn-default h48 pull-right'
                            onClick={this.openTekstbit.bind(this)}>
                            <NKIcon icon='kopier' color='nk-black' />
                          </button>
                          <button
                            type='button'
                            className='btn btn-default pull-right h48'
                            onClick={this.openTekstbitSoek.bind(this)}>
                            <NKIcon icon='tekst' color='nk-black' />
                          </button>
                        </div>
                        <br />
                        <textarea
                          id='bemerkningArea'
                          className='s100in'
                          ref={this.areaRef}
                          placeholder='Skriv her...'
                          defaultValue={this.state.avvik.beskrivelse}
                          onChange={this.beskrivelseChange.bind(this)}
                          onSelect={this.areaSelected.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={accStyle}
              className='container-fluid btn btn-default sbpilpanel collapsed'
              href='#bilder'
              onClick={this.startHentBilder.bind(this)}
              data-toggle='collapse'>
              <h4 style={{ display: 'inline-block' }}>
                Bilder ({this.state.antallBilder})
              </h4>
              <span style={chevronStyle} className='chevron fa fa-fw' />
            </div>

            {/* Bilder-del */}
            <div id='bilder' style={{ padding: '0 15px' }} className='collapse'>
              <div className='tab-pane idiv' id='bilder'>
                <div>
                  <BilderPanel
                    onMounted={this.bilderMounted}
                    onlisteChange={this.onfillListeChange.bind(this)}
                    filListe={this.state.filer}
                    onBildeSlettet={this.onBildeSlettet.bind(this)}
                    modul='Avvik'
                  />
                </div>
              </div>
            </div>

            <div
              style={accStyle}
              className='container-fluid btn btn-default sbpilpanel collapsed'
              href='#dialog'
              onClick={this.startHentBilder.bind(this)}
              data-toggle='collapse'>
              <h4 style={{ display: 'inline-block' }}>Avviksdialog</h4>
              <span style={chevronStyle} className='chevron fa fa-fw' />
            </div>

            <div id='dialog' style={{ padding: '0 15px' }} className='collapse'>
              {/* tab InnbyggerDialog */}
              <div className='tab-pane idiv' id='dialog'>
                <DialogHistorikkAvvik avvikId={endretAvvik.id} />
              </div>
            </div>
          </div>
        </div>

        {/*  Lagre og Slett   */}
        <div
          className='nk-modal-footer'
          style={{
            marginTop: '-80px',
            height: '80px',
            backgroundColor: 'white',
          }}>
          <div className='btn-toolbar pull-right' role='toolbar'>
            {contextInnstillingerPerson._currentValue.norkartAdmin &&
              this.props.id !== '0' && (
                <button
                  type='button'
                  onClick={this.slettAvvikDialog.bind(this)}
                  className='btn btn-default h48'>
                  Slett dialog på avvik
                </button>
              )}
            <button
              type='button'
              onClick={this.slett.bind(this)}
              className='btn btn-default h48'>
              <NKIcon
                icon='soppelbotte'
                size='0.8'
                color='nk-black'
                style={{ marginRight: '5px' }}
              />
              Slett
            </button>
            <button
              type='button'
              onClick={this.lagre}
              className='btn btn-primary h48'
              style={{ padding: '0 28px' }}>
              OK
            </button>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default AvvikForm;
