import React, { Component, Fragment } from 'react';
import CONFIG from 'lib/CONFIG';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import './dokeditor.css';
import ReactFileReader from 'react-file-reader';
import { debugFactory } from 'utils';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/languages/nb.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/plugins/image.min.js';
import MalsoekDialog from './MalsoekDialog.jsx';
import { flettMal, flettMalVedLukk } from 'api/innbyggerDialogApi';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';
import Forhandsvisning from './Forhandsvisning.jsx';
import LagreTekstbit from 'modules/felles/avvikAnmerkninger/LagreTekstbit.jsx';
import SoekTekstbit from 'modules/felles/avvikAnmerkninger/SoekTekstbit.jsx';
import TabellBorderDialog from './TabellBorderDialog.jsx';
import {
  checkEditorNormal,
  skjulTabellKantlinjer,
  visTabellKantlinjerIgjen,
  getMottakerVarselEierDialog,
  getMalDokumentPrMottaker,
} from './EditorNormal.js';

const debug = debugFactory('nk:DokEditor');
const FROALA_KEY = CONFIG.REACT_APP_FROALA;
const bStyle = '1px dotted white';
const cStyle = '1px dotted lightgray';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),

  TekstbiterDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <LagreTekstbit
        modul='avvik'
        merkettekst={dialogData.merkettekst}
        tekst={dialogData.tekst}
        onClose={onClose}
        onLagret={onClose}
      />
    </NKModal>
  ),
  TekstbiterSoekDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <SoekTekstbit
        modul='avvik'
        onClose={onClose}
        onVelgTekst={onClose(owner.onInsertTekstbit)}
      />
    </NKModal>
  ),

  MalSoekDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <MalsoekDialog
        onClose={onClose}
        onInsertMal={onClose(owner.onInsertMal)}
        modul={'O'}
        tiltakTypeId={owner.props.tiltakTypeId}
        skalLukkes={owner.props.skalLukkes}
      />
    </NKModal>
  ),

  ForhandsvisningDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <Forhandsvisning
        onClose={onClose}
        model={owner.props.model}
        // visToppTekstButton= {true}
      />
    </NKModal>
  ),
  BorderDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <TabellBorderDialog
        onClose={onClose}
        element={dialogData.element}
        elementNavn={dialogData.elementNavn}
      />
    </NKModal>
  ),
};

class SidemannEditor extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.froalaRef = null;
    this.kommentarRef = null;
    this.fileReader = null;
    this.state = {
      maler: null,
      originalModel: '',
      eierDataListe: [],
      ikkeFlettet: '',
      visKommentarPanel: false,
    
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  /*   componentDidMount = () => {
        let model = this.props.model;
      model = lastTestData(model);
      this.setState({ model }); 
  };*/

  //*********************KOMMENTAR*************************************************************************************** */

  /*   setNyKommentar = () => {
    if (!this.state.visKommentarPanel) {
      this.visKommentarPanel();
    }
    let editor = this.froalaRef.editor;

    editor.selection.restore();
    let tekst = editor.selection.text();
    if (!tekst) {
     // alert('Ingen tekst merket ut..!');
      this.dialogs.setOpenDialog('OkDialog', {
        melding: 'Merk ut noe i teksten først..',
        tittel: 'Ny kommentar',
      });
      return;
    }
    onNyKommentar(tekst, this.KommentarRef, editor);
  }; */

  //************************************************************************************************************ */

  componentDidMount = () => {
    this.editor = this.froalaRef;
    /* this.setState({ model });
    model = checkEditorNormal(model); */
    this.makeFroalaToolButtons();
    let bruksenhetids = [];
    bruksenhetids.push(this.props.bruksenhetId.toString());
  };


  makeFletteOptions = () => {
    let liste = this.props.fletteOptions;
    let fletteOptions = {};
    for (let i = 0; i < liste.length; i++) {
      const el = liste[i];
      if (el.result) {
        fletteOptions[el.result + ' '] = el.text;
      }
    }
  };

  onInsertMal = (mal) => {
    let MalId = mal.id;
    let FunksjonId = mal.funksjonId;
    let AvvikId = this.props.avvikId;
    let BruksenhetId = this.props.bruksenhetId;

    let delt = false;
    if (this.props.bruksenhetIdsTilknyttet) {
      delt = this.props.bruksenhetIdsTilknyttet.includes(',');
    }

    let dto = {
      MalId: MalId,
      FunksjonId: FunksjonId,
      AvvikId: AvvikId,
      BruksenhetId: BruksenhetId,
      BruksenhetdataIkkeFlettes: delt,
    };

    flettMal(dto, this.gotFlettetTekst);
  };

  setNotEdibleTextMottaker = (tekst) => {
    if (!this.props.ikkeFlettModus) {
      let mott = getMottakerVarselEierDialog();
      tekst = tekst.replaceAll('#mottaker#', mott);
    }

    return tekst;
  };

  gotFlettetTekst = (err, val) => {
    if (val) {
      let tekst = this.setNotEdibleTextMottaker(val.tekst);
      let editor = this.froalaRef.editor;
     
      editor.selection.restore();
      editor.html.insert(tekst);
      this.setState({ model: editor.html.get() });
      if (this.props.ikkeFlettModus) {
        this.sjekkOmAltErFlettet(val.tekst);
      }
      this.props.malHentet();
      this.fixScrollToTop(editor);
    }
   
  };

  fixScrollToTop = (editor) => { 
    setTimeout(() => {
      editor.$wp.scrollTop(0);
    }, 1000);
  };

  sjekkOmAltErFlettet = (tekst) => {
    let matches = [];
    matches = tekst.match(/#\w+#/g);
    let ikkeFlettet = '';
    if (matches) {
      matches = matches.filter(
        (value, index, categoryArray) => categoryArray.indexOf(value) === index
      );
      ikkeFlettet = matches.join(', ');
    }
    this.setState({ ikkeFlettet });
  };

  velgMal = () => {
    let editor = this.froalaRef.editor;
    editor.selection.save();
    this.dialogs.setOpenDialog('MalSoekDialog');
  };

  settInnFlettefelt = (cmd, val) => {
    let editor = this.froalaRef.editor;
    if (!editor) {
      //return;
    }

    for (let i = 0; i < this.props.fletteOptions.length; i++) {
      const element = this.props.fletteOptions[i];
      if (element.text.toString() + ' ' === val.toString()) {
        editor.selection.save();
        editor.selection.restore();
        editor.html.insert(element.result);
        this.props.handleModelChange();
      }
    }
  };

  makeFroalaToolButtons = () => {
    Froalaeditor.DefineIcon('star', {
      NAME: 'star',
      SVG_KEY: 'star',
    });
    Froalaeditor.RegisterCommand('star', {
      title: 'Testknapp',
      icon: 'star',
      callback: () => {
        alert('Test..');
      },
    });

    Froalaeditor.DefineIcon('tekstbiter', {
      NAME: 'Tekstbiter',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('tekstbiter', {
      title: 'Tekstbiter',
      type: 'dropdown',
      focus: true,
      undo: true,
      options: {
        SettInn: 'Sett inn tekstbit',
        TaVaere: 'Lagre tekstbit',
      },
      refreshAfterCallback: true,
      callback: (cmd, val) => {
        this.handleTekstbiter(cmd, val);
      },
    });

    Froalaeditor.DefineIcon('maltekst', {
      NAME: 'Velg mal',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('maltekst', {
      title: 'Bruk mal',
      icon: 'maltekst',
      callback: () => {
        this.velgMal();
      },
    });

    Froalaeditor.DefineIcon('brukmaltekst', {
      NAME: 'Bruk mal',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('brukmaltekst', {
      title: 'Bruk mal',
      icon: 'brukmaltekst',
      callback: () => {
        this.velgMal();
      },
    });

    //let fOptions = this.makeFletteOptions();
    let liste = this.props.fletteOptions;
    let fletteOptions = {};
    for (let i = 0; i < liste.length; i++) {
      const el = liste[i];
      if (el.result) {
        fletteOptions[el.text + ' '] = el.text;
      }
    }

    Froalaeditor.DefineIcon('flettefelt', {
      NAME: 'Sett inn',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('flettefelt', {
      title: 'Sett inn flettefelt',
      type: 'dropdown',
      focus: false,
      undo: false,
      options: fletteOptions,
      refreshAfterCallback: true,
      callback: (cmd, val) => {
        this.settInnFlettefelt(cmd, val);
      },
    });

    Froalaeditor.DefineIcon('cellbordersDialog', {
      NAME: 'cog',
      SVG_KEY: 'cellStyle',
    });
    Froalaeditor.RegisterCommand('cellbordersDialog', {
      title: 'Kantlinjer celle',
      callback: () => {
        this.froalaRef.editor.popups.hide('table.edit');
        var celleArray = document.getElementsByClassName('fr-selected-cell');
        var celle = celleArray[0];
        this.dialogs.setOpenDialog('BorderDialog', {
          element: celle,
          elementNavn: ' celle',
        });
      },
    });

    Froalaeditor.DefineIcon('tablebordersDialog', {
      NAME: 'cog',
      SVG_KEY: 'tableStyle',
    });
    Froalaeditor.RegisterCommand('tablebordersDialog', {
      title: 'Kantlinjer tabell',
      callback: () => {
        this.froalaRef.editor.popups.hide('table.edit');
        var celleArray = document.getElementsByClassName('fr-selected-cell');
        var parentTable = celleArray[0].offsetParent;
        this.dialogs.setOpenDialog('BorderDialog', {
          element: parentTable,
          elementNavn: ' tabell',
        });
      },
    });

    Froalaeditor.DefineIcon('pageBreaker', {
      NAME: 'Sideskift',
      SVG_KEY: 'pageBreaker',
    });
    Froalaeditor.RegisterCommand('pageBreaker', {
      title: 'Sideskift',
      icon: 'pageBreaker',
      callback: () => {
        this.sideskift();
      },
    });

    Froalaeditor.DefineIcon('clear', { NAME: 'remove', SVG_KEY: 'remove' });
    Froalaeditor.RegisterCommand('clear', {
      title: 'Fjern alt',
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      callback: function() {
        this.html.set('');
        this.events.focus();
      },
    });
  };

  handleTekstbiter = (cmd, val) => {
    if (val === 'SettInn') {
      this.settInnTekstbit();
    } else {
      this.lagreTekstbit();
    }
  };

  onInsertTekstbit = (val) => {
    let editor = this.froalaRef.editor;
    editor.selection.restore();
    editor.html.insert(val);
    this.props.handleModelChange();
  };

  lagreTekstbit = () => {
    let editor = this.froalaRef.editor;
    let s = editor.selection.text();
    s = this.removeHTML(s);
    if (s) {
      this.dialogs.setOpenDialog('TekstbiterDialog', {
        tekst: s,
        merkettekst: s,
      });
    }
  };

  settInnTekstbit = () => {
    let editor = this.froalaRef.editor;
    editor.selection.save();
    this.dialogs.setOpenDialog('TekstbiterSoekDialog');
  };

  forhandsvisning = () => {
    this.dialogs.setOpenDialog('ForhandsvisningDialog');
  };

  openFileReader = () => {
    let fileReader = this.fileReaderRef;
    fileReader.click();
  };

  removeHTML = (str) => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || '';
  };

  getDokumentTekst = () => {
    return this.props.model;
  };

  handleFiles = (files) => {
    let imstring =
      '<img src="' +
      files.base64 +
      '" alt="Bilde x" class="fr-fic fr-dii"></img>';
    let editor = this.froalaRef.editor;
    editor.selection.save();
    editor.selection.restore();
    editor.html.insert(imstring);
    editor.helpers.scrollTop();
  };

  afterUndoRedo = (editor) => {
    this.props.afterUndoRedo(editor);
  };

  render() {
    /*     if (this.props.model === '') {  //Rieni
      return <div style={{ padding: '40px' }}>...</div>;
    } */

    let visHTML = 'html';
    let visStar = 'star';

    /*   let visKommentar = 'kommentar'; */
    let visMalKnapp = this.props.parent === 'UB' ? 'maltekst' : '';
    if (this.props.skalLukkes) {
      visMalKnapp = 'brukmaltekst';
    }

    const config = {
      key: FROALA_KEY,
      attribution: false,
      placeholderText: 'Dokument..',
      autoFocus: true,
      fontFamilySelection: true,
      fontSizeSelection: true,
      /*       paragraphFormat: {
        N: 'Normal',
        H1: 'Heading 1',
        H2: 'Heading 2',
        H3: 'Heading 3',
        H4: 'Heading 4',
      }, */
      paragraphFormatSelection: false,
      quickInsertEnabled: false,
      tableEditButtons: [
        'tableHeader',
        'tableRemove',
        '|',
        'tableRows',
        'tableColumns',
        'tablebordersDialog',
        '-',
        'tableCells',
        'tableCellBackground',
        '|',
        'tableCellVerticalAlign',
        'tableCellHorizontalAlign',
        'cellbordersDialog',
      ],

      tableColors: [
        '#61BD6D',
        '#1ABC9C',
        '#54ACD2',
        '#2C82C9',
        '#9365B8',
        '#475577',
        '#CCCCCC',
        '#41A85F',
        '#00A885',
        '#3D8EB9',
        '#2969B0',
        '#553982',
        '#28324E',
        '#000000',
        '#F7DA64',
        '#FBA026',
        '#EB6B56',
        '#E25041',
        '#A38F84',
        '#EFEFEF',
        '#FFFFFF',
        '#FAC51C',
        '#F37934',
        '#D14841',
        '#B8312F',
        '#7C706B',
        '#D1D5D8',
        'REMOVE',
      ],
      listAdvancedTypes: false,
      spellcheck: false,
      tabSpaces: 4,
      imagePaste: false,
      language: 'nb',
      heightMin: 200,
      heightMax: 'calc(100vh - 300px)', 
      /*       height: 'calc(100vh - 100px)', */
      lineheight: '1.15',
      charCounterCount: true,
      lineHeights: {
        Standard: '1',
        '1.15': '1.15',
        '1.5': '1.5',
        Dobbel: '2',
      },
      events: {
        /* disable dersom det er Godkjent */
        initialized: (e) => {
          if (this.props.isDisabled) {
            setTimeout(() => {
              let editor = this.froalaRef.editor;
              editor.edit.off();
            }, 1000);
          }
        },
        'commands.before': (e) => {
          if (e === 'print') {
            let editor = this.froalaRef.editor;
            this.setState({ originalModel: this.props.model });
            return this.skjulTabellKantlinjerOgMottaker(editor);
            //return skjulTabellKantlinjer(editor);
          }
        },
        'commands.after': (f) => {
          if (f === 'print') {
            this.props.setEditorTekst(this.state.originalModel);
            let editor = this.froalaRef.editor;
            return visTabellKantlinjerIgjen(editor);
          }
          if (f === 'undo') {
            let editor = this.froalaRef.editor;
            return this.afterUndoRedo(editor);
          }
          if (f === 'redo') {
            let editor = this.froalaRef.editor;
            return this.afterUndoRedo(editor);
          }
        },
        mousedown: (e) => {
          /*     if (e.currentTarget.nodeName === 'SPAN') {
            onKommentarClick(e, this.KommentarRef, this.froalaRef.editor);
          } */
          /*     if (this.props.isDisabled) {
            let editor = this.froalaRef.editor;
              editor.edit.off();
          }  */
        },
      },

      fontFamily: {
        'Arial,Helvetica,sans-serif': 'Arial',
        'Verdana,Geneva,sans-serif': 'Verdana',
        'Open Sans': 'Open Sans',
        'Calibri': 'Calibri',
        'Times New Roman,Times,serif': 'Times New Roman',
        'Roboto,sans-serif': 'Roboto',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        'Oswald,sans-serif': 'Oswald',
        'Montserrat,sans-serif': 'Montserrat',
        Handlee: 'Handlee',
      },
      fontSize: [
        '8',
        '10',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '20',
        '24',
        '30',
        '60',
      ],
      toolbarButtons: {
        moreText: {
          buttons: [
            visMalKnapp,
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            'fontFamily',
            '|',
            'fontSize',
            '|',
            'paragraphFormat',
            '|',
            'textColor',
            'backgroundColor',
          ],
          align: 'left',
          buttonsVisible: 4,
        },
        moreMisc: {
          buttons: [
            'undo',
            'redo',
            'specialCharacters',

            visStar,
            /*    visKommentar, */
            visHTML,
          ],
          align: 'right',
          buttonsVisible: 2,
        },
        moreParagraph: {
          buttons: [
            'alignLeft',
            'alignCenter',
            'formatOL',
            'formatUL',
            'alignRight',
            'alignJustify',
            'lineHeight',
            'outdent',
            'indent',
          ],
          align: 'left',
          buttonsVisible: 4,
        },
        moreRich: {
          buttons: ['flettefelt', 'tekstbiter'],
        },
      },
    };
    return (
      <div>
        <div style={{ maxWidth: '776px', minWidth: '776px' }}>
          <FroalaEditorComponent
            ref={(el) => {
              this.froalaRef = el;
            }}
            id='editor'
            useClasses={false}
            config={config}
            documentReady={true}
            model={this.props.model}
            onModelChange={this.props.handleModelChange}
          />
        </div>

        <ReactFileReader
          base64={true}
          multipleFiles={false}
          fileTypes={['.gif, .jpg, .png, .jpeg']}
          handleFiles={this.handleFiles}>
          <button
            ref={(fl) => {
              this.fileReaderRef = fl;
            }}
            className='btn'
            style={{ display: 'none' }}>
            Last opp image
          </button>
        </ReactFileReader>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default SidemannEditor;
