import React from 'react';
import './AvvikAnmerkninger.css';
import anmerkningApi from 'api/anmerkningApi';
import avvikApi from 'api/avvikApi';

class LagreTekstbit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tittel: 'Lagre tekst for senere bruk?',
            sporsmal1: 'Ønsker du å ta vare på teksten som du har merket ut?',
            sporsmal2: '',
            tekst: ''
        };
    }

    componentDidMount() {
        if (this.props.merkettekst === '') {

            this.setState({
                sporsmal1: 'Du har IKKE merket ut noe.',
                sporsmal2: 'Ønsker du å ta vare på HELE teksten?',
                tekst: this.props.tekst
            });
        } else {
            this.setState({ tekst: this.props.merkettekst });
        }  
    }

    lagre() {
        var tekst = this.state.tekst;
        var nyTekst = { id: 0, tekst: tekst, aktiv: true };
        
        if (this.props.modul === 'anmerkninger') {
            anmerkningApi.createAnmerkningAdmBeskrivelse(nyTekst, this.lagretTekst.bind(this));
        } else {
            avvikApi.createAvvikAdmBeskrivelse(nyTekst, this.lagretTekst.bind(this));
        };
    }

    onClose() {
        console.log('************ close 2 ************************');
        this.props.onClose();
    }

    lagretTekst() {
        this.props.onLagret();    
    }
   
    render() {
        return (
                <div className="modal-content">
                    <div className="modal-header">
                        <button className="close" type="button" onClick={this.onClose.bind(this)}>
                            <span>x</span>
                            <span className="sr-only">Lukk</span>
                        </button>
                        <h4 className="modal-title"> {this.state.tittel}  </h4>
                    </div>
                    <div className="modal-body divTekstbiter">
                       <label>{this.state.sporsmal1}</label>
                       <br/>
                       <label>{this.state.sporsmal2}</label>
                       <br/><br/>
                       <div className='tekstbit'>{this.state.tekst}</div>

                       <br/><br/>
                        <div className="btn-toolbar pull-right" role="toolbar">
{/*                             <button type="button" onClick={this.props.onClose} className="btn btn-default h48">
                            &nbsp;&nbsp;&nbsp;Nei&nbsp;&nbsp;&nbsp;</button>  */}
                            <button type="button" onClick={this.lagre.bind(this)} className="btn btn-primary h48">
                            &nbsp;&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;&nbsp;</button> 
                        </div>
                        <br/><br/>
                    </div>
                    
                </div>

            );
    }
};

export default LagreTekstbit;