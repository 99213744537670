import React, { useState, useEffect } from 'react';
import { getContextBrukere } from 'context/contextOppslag.js';
import _ from 'underscore';
import { getInnloggetPerson } from 'context/contextInnstillinger.js';
import { byttSidemann, byttSidemannPaAvvik } from 'api/smkApi.js';

let blank = {
  id: 0,
  navn: '-',
};

function ByttSidemann(props) {
  const [personer, setPersoner] = useState(null);
  const [person, setPerson] = useState(null);
  const [isInit, setInit] = useState(true);
  const [changed, setChanged] = useState(false);
  const [innloggetPerson, setInnloggetPerson] = useState(null);
  const [userOk, setUserOk] = useState(false);

  useEffect(() => {
    if (isInit) {
      // ellers blir det en infin. loop
      setInit(false);
      getInnloggetPerson((person) => {
        console.log(
          '%c zxx tittel ',
          'font-size: 14px; background: black; color: white',
          person
        );
        setInnloggetPerson(person);
      });

      getContextBrukere(function(brukere) {
        console.log(brukere);
        let sidemenn = _.filter(
          brukere.brukere,
          (item) => item.sidemannkontroll === true && item.paaLogget === false
        );
        if (sidemenn.length){
          if (sidemenn[0] !== blank){
            sidemenn.unshift(blank);
          }
        } 
        setPersoner(sidemenn);
        setPerson(sidemenn[0].id);
        console.log(
          '%c zxx tittel ',
          'font-size: 14px; background: black; color: white',
          sidemenn[0]
        );
      });
    }
  });

  useEffect(() => {
    if (changed) {
      if (props.type === 'avvik') {
        byttSidemannPaAvvik(props.valgtItem.id, person, props.onClose);
      } else {
        if (props.type !== 'UnderArbeid') {
          byttSidemann(props.valgtItem.id, person, props.onClose);
        }
      }
    }
  });

  const handleAnsvarligChange = (item) => {
    setPerson(item.target.value);
    if (item.target.value !== 0) {
      setUserOk(true);
    } 
    if (item.target.value == 0) {
      setUserOk(false);
    }
  };

  const onOk = () => {
    console.log('ok bytt sidemann');
    if (person == null && personer != null && personer.length > 0) {
      setPerson(personer[0].id);
      setChanged(true);
    } else {
      if (props.type === 'avvik') {
        byttSidemannPaAvvik(props.valgtItem.id, person, props.onClose);
      } else {
        if (props.type === 'UnderArbeid') {
          props.onOk(person);
          props.onClose();
        } else {
          byttSidemann(props.valgtItem.id, person, props.onClose);
        }
      }
    }
  };

  const onOkJeg = () => {
    if (person == null && personer != null && personer.length > 0) {
      setPerson(innloggetPerson.id);
      setChanged(true);
    } else {
      if (props.type === 'avvik') {
        byttSidemannPaAvvik(props.valgtItem.id, innloggetPerson.id, props.onClose);
      } else {
        if (props.type === 'UnderArbeid') {
          props.onOk(innloggetPerson.id);
          props.onClose();
        } else {
          byttSidemann(props.valgtItem.id, innloggetPerson.id, props.onClose);
        }
      }
    }
  };

  return (
    <div
      style={{
        margin: '4px',
        padding: '10px',
      }}>
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> {props.tittel} </h4>
        </div>
        <div className='modal-body'>
          <div className='form'>
            <div className='row'>
              <div className='col-sm-12'>
                <p />
                <div className='form-group'>
                  <label className='top-margin-4'>
                    &nbsp;&nbsp;Velg sideperson&nbsp;&nbsp;&nbsp;
                  </label>
                  <select
                    className='h30 width220'
                    value={person}
                    id='type'
                    onChange={handleAnsvarligChange}>
                    {_.map(personer, function(o, i) {
                      return (
                        <option key={i} value={o.id}>
                          {o.navn}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          {innloggetPerson && innloggetPerson.norkartBruker && (
            <button className='btn btn-info' onClick={onOkJeg}>
              Jeg !
            </button>
          )}

          {userOk ? <div>
            <button className='btn btn-primary' onClick={onOk}>
            OK
          </button>
          </div> : <div>
          <button className='btn btn-primary' onClick={onOk} disabled>
            OK
          </button>
            </div>}   
        </div>
      </div>
    </div>
  );
}

export default ByttSidemann;
